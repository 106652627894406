import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getDataFromApi } from "./reportList/apis";
import { setDatesFromChart, sortDates } from "./utils";
import { useHistory } from "react-router-dom";
import { SET_FILTERS } from "../redux/actions";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    // maxWidth: 150,
    height: 250,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    margin: 20,
  },
}));

const options = {
  responsive: true,
  // maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const Item = ({ report }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    filters: {
      toDate,
      fromDate,
      selectedGroup,
      selectedPointsType,
      selectedPeriod = "daily",
      selectedActivity,
    } = {},
  } = useSelector((state) => state.commonReducer);
  const [summaryData, setSummaryData] = useState({});
  const [elevation, setElevation] = React.useState(1);
  const { reportType } = useParams();

  const getApiUrl = () => {
    return report.summaryApi(
      `?start=${moment(fromDate).format("MM/DD/yyyy")}&end=${moment(
        toDate
      ).format("MM/DD/yyyy")}${
        selectedGroup
          ? `&school=${selectedGroup === "All" ? "" : selectedGroup}`
          : ""
      }${reportType === "activities" ? `&program_id=${selectedActivity}` : ""}${
        reportType === "points" ? `&type=${selectedPointsType}` : ""
      }`
    );
  };

  useEffect(() => {
    if (report.summaryApi) {
      getDataFromApi(getApiUrl(), (res) => {
        setSummaryData({
          daily: sortDates(res[0]?.daily, "day"), // res[0]?.daily || [],
          weekly: sortDates(res[0]?.weekly, "week"), // res[0]?.weekly || [],
          monthly: sortDates(res[0]?.monthly, "month"), // res[0]?.monthly || [],
        });
      });
    }
  }, [
    reportType,
    fromDate,
    toDate,
    selectedGroup,
    selectedActivity,
    selectedPointsType,
  ]);

  const increasePaperElevaton = () => {
    setElevation(3);
  };
  const decreasePaperElevaton = () => {
    setElevation(1);
  };

  return (
    <Paper
      className={classes.paper}
      elevation={elevation}
      onMouseEnter={increasePaperElevaton}
      onMouseLeave={decreasePaperElevaton}
      style={{ height: !report.chart ? 50 : "" }}
    >
      <div
        style={{
          cursor: "pointer",
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {report?.chart ? (
          <div style={{height: 220, width: 400}}>
          <Line
            legend={{ display: false }}
            onElementsClick={(e) => {
              if (e?.length > 0) {
                setDatesFromChart({
                  dispatch,
                  selectedPeriod,
                  date: summaryData[selectedPeriod][e[0]._index],
                  navigate: () => history.push(report.path),
                });
              }
            }}
            data={{
              labels:
                summaryData[selectedPeriod]?.length > 0
                  ? summaryData[selectedPeriod].map((item) => {
                      if (selectedPeriod === "monthly") {
                        return moment(item.month, "YYYY-MM").format("MMM-YYYY");
                      }
                      if (selectedPeriod === "daily") {
                        return moment(item.day, "YYYY-MM-DD").format(
                          "DD/MMM/YYYY"
                        );
                      }
                      if (selectedPeriod === "weekly") {
                        return item.week;
                      }
                    })
                  : [],
              datasets: [
                {
                  data:
                    summaryData[selectedPeriod]?.length > 0
                      ? summaryData[selectedPeriod].map((item) => {
                          return item.count || item?.total;
                        })
                      : [],
                  fill: false,
                  backgroundColor: "rgb(255, 99, 132)",
                  borderColor: "rgba(255, 99, 132, 0.2)",
                },
              ],
            }}
            options={options}
          />
          </div>
        ) : null}
        <Link
          to={report.path}
          onClick={() => {
            if (report?.onClick) {
              report?.onClick();
            } else {
              dispatch({
                type: SET_FILTERS,
                payload: {
                  data: {
                    listToDate: toDate,
                    listFromDate: fromDate,
                  },
                },
              });
            }
          }}
        >
          <span
            style={{
              textAlign: "center",
              marginTop: 60,
            }}
          >
            {report.heading}
          </span>
        </Link>
      </div>
    </Paper>
  );
};

export default Item;
