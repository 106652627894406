import { Box, Modal, Typography } from "@material-ui/core";
import React from "react";
import videoURL from "../../constants/videos/demo-video.mp4";

const AllRegistrationsVideoModal = ({ open, handleClose, data }) => {
  console.log(data,"newData");
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      {/* <Typography id="modal-modal-title" variant="h6" component="h2">
        Video Player
      </Typography> */}
        <video controls width="100%" src={data.video} height={400}/>
      </Box>
    </Modal>
    // <></>
  );
};

export default AllRegistrationsVideoModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
