import { combineReducers } from "redux";
import videoGallery from "../components/videos/reducer";
import connects from "../components/connects/reducers";
import allUsers from "../components/users/reducers";
import commonReducer from "./common-reducer";
import videoComments from "../components/comments/reducers";
import coinsLedger from "../components/coins-ledger/reducers";
import qnas from "../components/qnaListing/reducer";

const appReducer = combineReducers({
  commonReducer: commonReducer,
  videoGallery: videoGallery,
  connects: connects,
  allUsers: allUsers,
  videoComments: videoComments,
  coinsLedger: coinsLedger,
  qnas: qnas,
});

const rootReducer = (state, action) => {
  // if (action.type === LOGOUT) {
  //   state = undefined;
  // }

  return appReducer(state, action);
};

export default rootReducer;
