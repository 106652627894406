import {
  VIDEO_GALLERY_REQUEST,
  VIDEO_GALLERY_SUCCESS,
  VIDEO_GALLERY_FAILURE,
  CATEGORY_REQUEST,
  CATEGORY_SUCCESS,
  CATEGORY_FAILURE,
} from "./actions";

import logger from "../../utils/logger";
import pageLimits from "../../utils/page-limits";

const initialState = {
  videoCommentsList: {},
};

const videoGallery = (state = initialState, action) => {
  console.log(action.type, "action.type");
  switch (action.type) {
    case CATEGORY_REQUEST: {
      return { ...state, loading: true };
    }
    case CATEGORY_SUCCESS: {
      return { ...state, category: action.payload.category, loading: false };
    }
    case CATEGORY_FAILURE: {
      return { ...state, data: action.payload, loading: false };
    }

    case VIDEO_GALLERY_SUCCESS: {
      let items = state.videosList?.docs?.slice(
        0,
        pageLimits.allVideos * (action.payload.page - 1)
      );
      return {
        ...state,
        videosList:
          action.payload.page === 1
            ? action.payload
            : {
                // ...action.payload,
                page: action.payload.page,
                pages: action.payload.pages,
                docs: [...items, ...action.payload.docs],
                total: action.payload.total,
              },
        loading: false,
      };
    }

    case VIDEO_GALLERY_REQUEST: {
      return { ...state, loading: true };
    }
    // case VIDEO_GALLERY_SUCCESS: {
    //   return { ...state, videosList: action.payload, loading: false };
    // }
    case VIDEO_GALLERY_FAILURE: {
      return { ...state, data: action.payload, loading: false };
    }
    default:
      return state;
  }
};
export default videoGallery;
