const MY_ACTIVITIES_REQUEST = "MY_ACTIVITIES_REQUEST";
const MY_ACTIVITIES_SUCCESS = "MY_ACTIVITIES_SUCCESS";
const MY_ACTIVITIES_FAILURE = "MY_ACTIVITIES_FAILURE";
const GET_PROGRAM_DETAILS_REQUEST = "GET_PROGRAM_DETAILS_REQUEST";
const GET_PROGRAM_DETAILS_SUCCESS = "GET_PROGRAM_DETAILS_SUCCESS";
const GET_PROGRAM_DETAILS_FAILURE = "GET_PROGRAM_DETAILS_FAILURE";
const GET_ACTIVITY_DETAILS_REQUEST = "GET_ACTIVITY_DETAILS_REQUEST";
const GET_ACTIVITY_DETAILS_SUCCESS = "GET_ACTIVITY_DETAILS_SUCCESS";
const GET_ACTIVITY_DETAILS_FAILURE = "GET_ACTIVITY_DETAILS_FAILURE";
const ACTIVITY_QNA_FAILURE = "ACTIVITY_QNA_FAILURE";
const ACTIVITY_QNA_REQUEST = "ACTIVITY_QNA_REQUEST";
const ACTIVITY_QNA_SUCCESS = "ACTIVITY_QNA_SUCCESS";
const SET_ONGOING_ACTIVITY_DETAILS = "SET_ONGOING_ACTIVITY_DETAILS";
export {
  MY_ACTIVITIES_REQUEST,
  MY_ACTIVITIES_SUCCESS,
  MY_ACTIVITIES_FAILURE,
  GET_PROGRAM_DETAILS_REQUEST,
  GET_PROGRAM_DETAILS_SUCCESS,
  GET_PROGRAM_DETAILS_FAILURE,
  GET_ACTIVITY_DETAILS_REQUEST,
  GET_ACTIVITY_DETAILS_SUCCESS,
  GET_ACTIVITY_DETAILS_FAILURE,
  SET_ONGOING_ACTIVITY_DETAILS,
  ACTIVITY_QNA_FAILURE,
  ACTIVITY_QNA_REQUEST,
  ACTIVITY_QNA_SUCCESS,
};
