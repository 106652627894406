import React from 'react';

const NoRecord = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'red',
      }}
    >
      No record found
    </div>
  );
};

export default NoRecord;
