import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SimpleModal = ({
  isModalOpen,
  heading,
  body,
  cancelCallback,
  yesCallback,
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const buttonStyle = {
    cursor: "pointer",
    backgroundColor: "blue",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 10,
  };
  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={cancelCallback}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">{heading}</h2>
          <p id="simple-modal-description">{body}</p>
          <div
            style={{
              display: "flex",
              //   flex: 1,
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={buttonStyle} onClick={() => cancelCallback()}>
              No
            </div>
            <div style={buttonStyle} onClick={() => yesCallback()}>
              Yes
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SimpleModal;
