import moment from "moment";
import { SET_LOADER, SET_FILTERS, SET_ALL_SCHOOLS } from "./actions";

const initialState = {
  loading: false,
  filters: {
    listToDate: new Date(),
    listFromDate: moment(new Date()).subtract(365, "days")._d,
    toData: new Date(),
    fromDate: moment(new Date()).subtract(365, "days")._d,
    selectedPeriod: "monthly",
    selectedGroup: "All",
    selectedActivity: "",
    selectedPointsType: "",
    selectedActivityStatus: "",
  },
  reports: {
    allSchools: [],
  },
};

const commonReducer = (state = initialState, action) => {
  console.log(action.type, "action.type");
  switch (action.type) {
    case "LOADING_TRUE": {
      return { ...state, loading: true };
    }
    case "LOADING_FALSE": {
      return { ...state, loading: false };
    }
    case SET_LOADER: {
      return { ...state, loading: action.payload };
    }
    case SET_FILTERS: {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload?.data },
      };
    }

    case SET_ALL_SCHOOLS: {
      return {
        ...state,
        reports: { ...state.reports, allSchools: action.payload.allSchools },
      };
    }

    default:
      return state;
  }
};
export default commonReducer;
