import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const LoaderOne = ({ loading }) => {
  const classes = useStyles();
  return loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }}
    >
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </div>
  ) : null;
};

export default LoaderOne;
