const baseUrlWithSlash = 'https://prod.thegenwe.com';
// const baseUrlWithSlash = 'https://prod.thegenwe.com/';
const baseUrlWithoutSlash = 'https://prod.thegenwe.com';
//"http://prod.genwe.cloud/";
//"http://prod.genwe.cloud/";
//"http://35.154.109.33:7001/";
//"http://dev.genwe.cloud/";

const apiUrls = {
  login: '/service/v0/dashboard/login',
  category: 'service/v0/constants/video/category',
  videoGallery: (category, user_id, page, limit) =>
    `service/v0/video/list?category=${category}&user_id=${user_id}&page=${page}&limit=${limit}&blocked=true`,
  getMyActivities: (user_id) =>
    `service/v0/user/program/list?user_id=${user_id}`,
  getProgramDetails: (program_id, user_id) =>
    `service/v0/user/program?program_id=${program_id}&user_id=${user_id}`,
  getActivityDetails: (activity_id, user_id) =>
    `service/v0/user/activity?activity_id=${activity_id}&user_id=${user_id}`,
  saveConnect: '/service/v0/user/program/add',
  genstat: 'service/v0/user/genstat',
  createProgram: 'service/v0/user/program/add',
  createActivity: 'service/v0/user/activity/add',
  updateProfileKey: 'service/v0/user/add',
  getAllUsers: (
    limit,
    page,
    phoneNumber,
    gender,
    username,
    name,
    email,
    paired,
    Priveleged,
    underPriveleged,
    ageMin,
    ageMax,
    blocked, verificationStatus
  ) =>
    `service/v0/user/list?limit=${limit}&page=${page}&gender=${gender}&username=${username}&name=${name}&phone_number=${phoneNumber}&email=${email}&paired=${paired}&priveleged=${Priveleged}&under_priveleged=${underPriveleged}&ageMin=${ageMin}&ageMax=${ageMax}&blocked=${blocked}&verificationStatus=${verificationStatus}`,

  pairUsers: '/service/v0/pair',
  getUserVerification: `/dashboard/get-verification-requests`,
  updateNewRegistration: (userId) => `/dashboard/update-verification-status/${userId}`,
  addUser: 'service/v0/user/add',
  addVideo: '/service/v0/video/add',
  addVideoComment: 'service/v0/video/comment/add',
  videoCommentList: (videoId, limit, page, showBlock = true) =>
    `service/v0/video/comment/list?video_id=${videoId}&limit=${limit}&page=${page}&show_blocked=${showBlock}`,
  videoBlockedCommentList: (limit, page) =>
    `service/v0/video/comment/list?limit=${limit}&page=${page}&blocked=true`,
  deleteUserComment: (id, videoId) =>
    `service/v0/video/comment/remove?id=${id}&video_id=${videoId}`,
  deleteUser: (userId) => `/service/v0/user/remove?id=${userId}`,
  userEngagement: (userId) => `/service/v0/user/engagement?id=${userId}`,
  activityResponse: (activityId, toggle = false) =>
    `/service/v0/user/activity/response/list?activity_id=${activityId}&toggle=${toggle}`,
  unpairUser: '/service/v0/unpair',
  dashboardStats: '/service/v0/dashboard/metrics',
  allUsersCallLogs: `service/v0/user/calllog/summary`,
  allQueries: (limit, page, enquiryType) =>
    `/service/v0/contact/us?limit=${limit}&page=${page}&type=${enquiryType}`,
  editVideo: `/service/v0/video/add`,
  getVideoLikes: (videoId) =>
    `/service/v0/video/list/likes?video_id=${videoId}`,
  callDetails: (start, end, minutes) =>
    `/service/v0/calllog/usage?start=${start}&end=${end}&minutes=${minutes}`,
  getUserPoints: (user_id) => `/service/v0/user/points?user_id=${user_id}`,
  webinarResponse: (webinarId, toggle = false) =>
    `/service/v0/webinar/list?webinar_id=${webinarId}&toggle=${toggle}`,
  getWebinars: '/service/v0/webinar/all',
  deleteVideo: (videoId) => `/service/v0/video/remove?id=${videoId}`,
  unblockUser: (userId) => `/service/v0/user/unblock?id=${userId}`,
  blockUser: (userId) => `/service/v0/user/block?id=${userId}`,
  checkValidUser: 'service/v0/username/valid',
  coinsLedger: (limit, page) =>
    `service/v0/user/points/list?limit=${limit}&page=${page}`,
  qnaReponse: (limit, page, questionId) =>
    `/service/v0/qna/get/response/list?limit=${limit}&page=${page}&id=${questionId}`,
  qnaListing: (limit, page) =>
    `/service/v0/qna/get/question/list?limit=${limit}&page=${page}`,
  getUserWisePoints: (limit, page) =>
    `/service/v0/user/points/total?limit=${limit}&page=${page}`,
  getVideoViews: (limit, page, videoId, toggle) =>
    `/service/v0/video/viewed/list?video_id=${videoId}&toggle=${toggle}`,
  getChatSummary: '/service/v0/user/chatlog/summary',
  sendPoints: '/service/v0/user/points/add',
  sendPointsMultiple: '/service/v0/points/add/bonus',
  sendPointsWebinar: '/service/v0/points/add/webinar',
  appendInAppNotification: '/service/v0/append/in/app/notification',
  pairedUsers: (limit, page) =>
    `/service/v0/user/get/paired?limit=${limit}&page=${page}`,
  unpairedUsers: (limit, page) =>
    `/service/v0/user/get/unpaired?limit=${limit}&page=${page}`,
  executeApi: 'service/v0/utility/execute/api',
  reports: {
    getAllSchools: '/service/v0/user/get/school/list',
    appDownloadsSummary: (query) =>
      `/service/v0/metrics/app/download/summary${query}`,
    appDownloadsList: (query) =>
      `/service/v0/metrics/app/download/list${query}`,
    registeredUsersSummary: (query) =>
      `/service/v0/metrics/registered/user/summary${query}`,
    registeredUsersList: (query) =>
      `/service/v0/metrics/registered/user/list${query}`,
    unpairedUsers: (query) => `/service/v0/metrics/unpaired/user/list${query}`,
    unpairedUsersSummary: (query) =>
      `/service/v0/metrics/unpaired/user/summary${query}`,
    pairedUsers: (query) => `/service/v0/metrics/paired/user/list${query}`,
    pairedUsersSummary: (query) =>
      `/service/v0/metrics/paired/user/summary${query}`,
    inductedUsersList: (query) =>
      `/service/v0/metrics/inducted/user/list${query}`,
    inductedUsersSummary: (query) =>
      `/service/v0/metrics/inducted/user/summary${query}`,
    chatsListReport: (query) => `/service/v0/metrics/chat/log/list${query}`,
    chatsListReportSummary: (query) =>
      `/service/v0/metrics/chat/log/summary${query}`,
    callLogListReport: (query) => `/service/v0/metrics/call/log/list${query}`,
    callLogListReportSummary: (query) =>
      `/service/v0/metrics/call/log/summary${query}`,

    userEngagementReport: (query) =>
      `/service/v0/metrics/active/inactive/user/list${query}`,
    userEngagementReportSummary: (query) =>
      `/service/v0/metrics/active/inactive/user/summary${query}`,
    chatListSupport: (query) => `/service/v0/metrics/support/chat/list${query}`,
    chatListSupportSummary: (query) =>
      `/service/v0/metrics/support/chat/summary${query}`,
    contactUsListSupport: (query) =>
      `/service/v0/metrics/contact/us/list${query}`,
    contactUsListSupportSummary: (query) =>
      `/service/v0/metrics/contact/us/summary${query}`,

    activityResponseListReport: (query) =>
      `/service/v0/metrics/activity/response/list${query}`,
    activityResponseListReportSummary: (query) =>
      `/service/v0/metrics/activity/response/summary${query}`,
    activityCompletionStatusReport: (query) =>
      `/service/v0/metrics/program/status/summary${query}`,
    userActivityCompletionStatusReport: (query) =>
      `/service/v0/metrics/program/stats/with/user${query}`,

    pointsSummaryReport: (query) =>
      `/service/v0/metrics/coin/ledger/point/summary${query}`,
    pointsLedgerReport: (query) =>
      `/service/v0/metrics/coin/ledger/list${query}`,

  },
  findPairingHistory: 'service/v0/pairing/history/find',
  findPairingHistoryPaginate: (page, limit) =>
    `service/v0/pairing/history/paginate?page=${page}&limit=${limit}`,
  subjects: {
    find: 'service/v0/subject/find',
    add: 'service/v0/subject/add',
    remove: '/service/v0/subject/remove',
  },
  variables: {
    find: (name) => `service/variable/find/one?name=${name}`,
  },
  inAppCards: {
    find: (query) => `service/v0/in/app/cards/find${query}`,
  },
  userEngagementLogs: {
    program: (query) => `/service/v0/metrics/program/stats/with/user${query}`,
    chatLog: (query) => `/service/v0/metrics/chat/log/list${query}`,
    callLog: (query) => `/service/v0/user/calllog/find${query}`,
    videoWatch: (query) => `/service/v0/video/viewed/details${query}`,
    metrices: (query = '') => `/service/v0/admin/metrics${query}`,
    webinarPoints: (query = '') =>
      `service/v0/points/with/user${query ? query + '&type=WEBINAR' : '?type=WEBINAR'
      }`,
    questionPoints: (query = '') =>
      `service/v0/points/with/user${query}`,
  },
  userPointsStat: (query) => `/service/v0/points/stats/with/user${query}`,
  contactUs: '/service/v1/contact/us',
  discountCoupons: {
    list: 'service/v0/discount/program/find',
    create: 'service/v0/discount/program/add',
    remove: 'service/v0/discount/program/remove',
    findOne: (query) => `service/v0/discount/program/find/one${query}`,
  },
  scoringMechenism: 'https://genwescoring.thegenwe.com/dashboard/getAverages'
};

export const moengageApiUrl =
  'https://api-03.moengage.com/v1/customer/6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG';
export const moengageApiUrlProd =
  'https://api-03.moengage.com/v1/customer/6KIBHYUWIG7V3DEQPZPYWQB2';
export const moengageEventApiUrlProd =
  'https://api-03.moengage.com/v1/event/6KIBHYUWIG7V3DEQPZPYWQB2';
export const moengageEventApiUrl =
  'https://api-03.moengage.com/v1/event/6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG';
export { baseUrlWithSlash as apiBaseUrl, baseUrlWithoutSlash };
export default apiUrls;
