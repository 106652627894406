import apiClient from './api-client';
import apiUrls, { moengageApiUrl, moengageApiUrlProd } from './apis';

const moengageApiKey = '6KIBHYUWIG7V3DEQPZPYWQB2'; //'6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG', '6KIBHYUWIG7V3DEQPZPYWQB2

const token = Buffer.from(
  `6KIBHYUWIG7V3DEQPZPYWQB2:ifxtGSDlz3HeHl3MhjSxX8T6`,
    'utf8'
  ).toString('base64');

// Buffer.from(
//   `6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG:b_z8o1t7xFlKT8HQ44_CbDwf`,
//   'utf8'
// ).toString('base64');

// Buffer.from(
// `6KIBHYUWIG7V3DEQPZPYWQB2:ifxtGSDlz3HeHl3MhjSxX8T6`,
//   'utf8'
// ).toString('base64');

const addUserData = async (body = {}) => { 
  try {
    const data = apiClient.post(apiUrls.executeApi, {
      method: 'POST',
      url: moengageApiUrlProd,
      headers: {
        Authorization: `Basic ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    });
    console.log(data, 'success-addUserData');
  } catch (e) {
    console.log(e, 'error-addUserData');
  }
};

const addUserEvent = async (body = {}) => {
  try {
    const data = apiClient.post(apiUrls.executeApi, {
      method: 'POST',
      url: `https://api-03.moengage.com/v1/event/${moengageApiKey}`,
      headers: {
        Authorization: `Basic ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    });
    console.log(data, 'success-addUserEvent');
  } catch (e) {
    console.log(e, 'error-addUserEvent');
  }
};

const deleteUserFromMoengage = async (body = {}) => {
  try {
    const data = apiClient.post(apiUrls.executeApi, {
      method: 'POST',
      url: `https://api-03.moengage.com/v1/opengdpr_requests/${moengageApiKey}`,
      headers: {
        Authorization: `Basic ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    });
    console.log(data, 'success-deleteUserFromMoengage');
  } catch (e) {
    console.log(e, 'error-deleteUserFromMoengage');
  }
};

export { addUserData, addUserEvent, deleteUserFromMoengage };
