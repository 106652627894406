const VIDEO_GALLERY_REQUEST = "VIDEO_GALLERY_REQUEST";
const VIDEO_GALLERY_SUCCESS = "VIDEO_GALLERY_SUCCESS";
const VIDEO_GALLERY_FAILURE = "VIDEO_GALLERY_FAILURE";
const CATEGORY_REQUEST = "CATEGORY_REQUEST";
const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
const CATEGORY_FAILURE = "CATEGORY_FAILURE";
export {
  VIDEO_GALLERY_REQUEST,
  VIDEO_GALLERY_SUCCESS,
  VIDEO_GALLERY_FAILURE,
  CATEGORY_REQUEST,
  CATEGORY_SUCCESS,
  CATEGORY_FAILURE,
};
