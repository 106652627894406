import React, { useState, useEffect } from "react";
import MenuAppBar from "../../routes/header";
//import { getChatSummary } from "./apis";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import logger from "../../utils/logger";
import { sendPoints } from "./apis";
import styles from "../login/styles";
import images from "../../constants/images";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import  {getAllUserAndPeerPoints} from "../userWisePointsPeerDetails/apis"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SendPoints = ({ activitySubjects = [], getConnects }) => {
  const classes = useStyles();
  const styles = {
    textFieldTop: { width: 320 },
    textField: { marginTop: 20, width: 400 },
    button: { marginTop: 20 },
  };
  const [type, setType] = useState("Bonus");
  const [points, setPoints] = useState("");
  const [title, setTitle] = useState("");
  const [userId, setUserId] = useState("");
  const [userIds, setUserIds] = useState([]);
  const [description, setDescription] = useState("");
  const [webinarId, setWebinarId] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [error1,setError1] = useState(false);
  const [notfound,setNotFound] = useState([]);

  useEffect(() => {
    getAllUserAndPeerPoints(
      pageNumber,
      3,
      setData,
      setPageNumber,
      setLoading,
      "",
      "",
    );
  }, [pageNumber]);

  useEffect(()=>{
    const newd = data?.map((dt)=>dt?.userId);
    setData1(newd)
  },[data])

  console.log(data,"userpoints",data1);

useEffect(()=>{
  for(let i=0;i<userIds.length;i++){
    if(data1.includes(userIds[i])){
      console.log("userpoints done");    
      // setError1(false);
    }else{
      setError1(true);
      console.log("userpoints not done");
    }
  }
  return ()=> setError1(false);
},[data1,userIds])

  const sendPointsBtn = () => {
    var reg = /^\d+$/;
    if (!reg.test(points)) {
      setError("Please enter valid points");
      return;
    }
    if(error1){
      alert("User not found")
      return;
    }
    sendPoints(
      {
        users: userIds,
        // type: "",
        // video_id: "",
        // activity_id: "",
        title: title,
        //title: type === "Webinar" ? `Webinar: ${title}` : `Bonus: ${title}`,
        description: description,
        points: points,
        meta: {},
        webinar_id: type === "Webinar" ? webinarId : "",
      },
      type,
      () => {
        setUserId("");
        setDescription("");
        setTitle("");
        setPoints("");
        setSuccessMessage(
          `Successfully added ${points} points to ${userId} userId`
        );
        setUserIds([]);
      },
      setError
    );
  };
  return (
    <div style={{}}>
      <MenuAppBar heading="Send Points" />
      <div
        style={{
          marginTop: 100,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
          padding: 40,
          alignSelf: "center",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <FormControl
          style={styles.textField}
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel
            //style={styles.textFieldTop}
            htmlFor="outlined-age-native-simple"
          >
            Type
          </InputLabel>
          <Select
            //style={styles.textFieldTop}
            native
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            label="Type"
            inputProps={{
              name: "Type",
            }}
          >
            {["Bonus", "Webinar"].map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </FormControl>
        <div className="d-flex flex-row">
          <TextField
            value={userId}
            id="outlined-basic"
            label="User id"
            style={styles.textFieldTop}
            variant="outlined"
            onChange={(e) => {
              setUserId(e.target.value);
              setSuccessMessage("");
              setError("");
            }}
          />
          <Button
            style={{ marginRight: 10, marginLeft: 10 }}
            variant="contained"
            color="primary"
            onKeyPress={(e) => {
              console.log("jfvjvh");
              if (e.key === "Enter") {
                console.log("jfvjvh");
                setUserIds([...userIds, userId]);
                setUserId("");
              }
            }}
            disabled={!/^\d+$/.test(userId)}
            onClick={() => {
              if (userIds.indexOf(userId) !== -1) {
                return;
              }
              console.log(userIds.indexOf(userId), "dcnsdkc");
              setUserIds([...userIds, userId]);
              setUserId("");
            }}
          >
            Add
          </Button>
        </div>
        {userIds.length > 0 && (
          <div
            style={{
              display: "grid",
              marginTop: 10,
              marginBottom: 10,
              borderRadius: 3,
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 10,
              border: "2px solid lightgrey",
              fontSize: 13,
              width: 400,
              padding: "4px 8px 4px 8px",
            }}
          >
            {userIds.map((id) => (
              <div
                onClick={() => {
                  const newUserIds = userIds.filter((val, i) => id !== val);
                  setUserIds([...newUserIds]);
                }}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 5,
                  backgroundColor: "lightgrey",
                  padding: "4px 8px 4px 8px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    margin: 2,
                    marginRight: 10,
                    color: "black",
                  }}
                >
                  {id}
                </p>
                <img style={{ height: 10, width: 10 }} src={images.x} />
              </div>
            ))}
          </div>
        )}
        <TextField
          value={title}
          id="outlined-basic"
          label="Title"
          style={styles.textField}
          variant="outlined"
          onChange={(e) => {
            setTitle(e.target.value);
            setSuccessMessage("");
            setError("");
          }}
        />
        <TextField
          value={description}
          id="outlined-basic"
          style={styles.textField}
          label="Description"
          variant="outlined"
          multiline
          rows={3}
          onChange={(e) => {
            setDescription(e.target.value);
            setSuccessMessage("");
            setError("");
          }}
        />
        <TextField
          value={points}
          id="outlined-basic"
          style={styles.textField}
          label="Points"
          variant="outlined"
          onChange={(e) => {
            setPoints(e.target.value);
            setSuccessMessage("");
            setError("");
          }}
        />
        {type === "Webinar" && (
          <TextField
            value={webinarId}
            id="outlined-basic"
            label="Webinar Id"
            style={styles.textField}
            variant="outlined"
            onChange={(e) => {
              setWebinarId(e.target.value);
              setSuccessMessage("");
              setError("");
            }}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          disabled={!points || !title || userIds.length === 0}
          onClick={() => {
            sendPointsBtn();
          }}
        >
          Send Points
        </Button>
        {successMessage ? (
          <p style={{ color: "green" }}>{successMessage}</p>
        ) : null}

        {error ? <p style={{ color: "red" }}>{error}</p> : null}
      </div>
    </div>
  );
};

export default SendPoints;
