import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const logout = (routerFunction) => {
  localStorage.clear();
  routerFunction();
};

const isLogin = () => {
  const lastLoginTime = localStorage.getItem('lastLoginTime');
  let isLoginValidated;

  let hours = Math.abs(new Date() - new Date(lastLoginTime)) / 36e5;
  console.log(
    hours,
    new Date(),
    new Date(lastLoginTime),
    'lastLoginTimelastLoginTime'
  );
  if (hours < 24) {
    isLoginValidated = true;
  } else {
    isLoginValidated = false;
    localStorage.clear();
  }
  return isLoginValidated;
};

export { logout, isLogin };
