import apiUrls from "../apis/apis";
import apiClient from "../apis/api-client.js";
import logger from "../utils/logger";

const getAllSchools = async (successCallBack = () => {}) => {
  try {
    const { data } = await apiClient.get(apiUrls.reports.getAllSchools);
    logger.log(data, "response-getAllSchools");
    successCallBack(data);
  } catch (error) {
    logger.log(error, "error-getAllSchools");
  }
};

export { getAllSchools };
