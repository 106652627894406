import React, { useEffect } from "react";
import { Route, Switch, HashRouter, BrowserRouter } from "react-router-dom";
import pages, { nonLoginPages } from "./pages";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { isLogin } from "../utils/helpers";
import Loader from "../utils/components/loader";

const Routes = ({ loading }) => {
  const login = isLogin();
  return !login ? (
    <>
      {nonLoginPages.map((item, index) => (
        <Route
          exact={item.exact}
          path={item.path}
          render={({ match, location }) => (
            <item.view location={location} match={match} />
          )}
          key={index}
        />
      ))}
    </>
  ) : (
    <>
      <Loader />
      {pages.map((item, index) => (
        <Route
          exact={item.exact}
          path={item.path}
          render={({ match, location }) => (
            <item.view location={location} match={match} />
          )}
          key={index}
        />
      ))}
    </>
  );
};

const mapStateToProps = ({ commonReducer: { loading = false } }) => ({
  loading,
});
export default connect(mapStateToProps)(Routes);
