import React, { useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';
import images from '../../../../constants/images/index';

const ImagePicker = ({
  title,
  name,
  type,
  file,
  setFieldValue,
  defaultImage,
}) => {
  const imageInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState('');

  const fileSelect = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      var res = reader.result.split(',');
      setFieldValue('avatar', res[1]);
      console.log(res[1], 'iitteemm');
      setSelectedImage(reader.result);
    };
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
        border: '2px solid lightgrey',
        padding: 10,
        borderRadius: 10,
      }}
    >
      {title && <span>{title}: </span>}
      <br />
      <input
        ref={imageInputRef}
        type='file'
        style={{ display: 'none' }}
        onChange={fileSelect}
        accept='.png, .jpg, .jpeg'
      />

      <img
        src={
          selectedImage
            ? selectedImage
            : file
            ? `data:image/png;base64,${file}`
            : defaultImage
        }
        style={{
          height: 80,
          width: 80,
          borderRadius: 50,
          objectFit: 'contain',
          cursor: 'pointer',
          marginLeft: 50,
        }}
        onClick={() => imageInputRef.current.click()}
      />
      <br />
    </div>
  );
};

export default ImagePicker;
