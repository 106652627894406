import apiUrls from '../../apis/apis';
import {
  CATEGORY_REQUEST,
  CATEGORY_SUCCESS,
  CATEGORY_FAILURE,
  VIDEO_GALLERY_REQUEST,
  VIDEO_GALLERY_SUCCESS,
  VIDEO_GALLERY_FAILURE,
} from './actions';
import apiClient from '../../apis/api-client.js';
import axios from 'axios';
import logger from '../../utils/logger';

const getCategoryList = () => async (dispatch) => {
  dispatch({ type: CATEGORY_REQUEST });
  try {
    const { data } = await apiClient.get(apiUrls.category);
    logger.log(data, 'response-getCategoryList');
    dispatch({ type: CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_FAILURE,
      payload: error.response,
    });
    logger.log(error, 'error-getCategoryList');
  }
};

const getVideoGalleryList = (category, user_id, page, limit) => async (
  dispatch
) => {
  dispatch({ type: VIDEO_GALLERY_REQUEST });
  logger.log(category.replace(/\s+/g, '_'), 'sdlcsdkc');
  try {
    const { data } = await apiClient.get(
      apiUrls.videoGallery(category.replace(/\s+/g, '_'), user_id, page, limit),
      {}
    );
    dispatch({ type: VIDEO_GALLERY_SUCCESS, payload: data });
    logger.log(data, 'response-getVideoGalleryList');
  } catch (error) {
    dispatch({
      type: VIDEO_GALLERY_FAILURE,
      payload: error.response,
    });
    logger.log(error, 'error-getVideoGalleryList');
  }
};

const addVideo = (videoObject) => async (dispatch) => {
  let videoId = videoObject._id;
  if (videoObject._id === '') {
    videoId = videoObject.title.replace(/\s+/g, '_').toLowerCase();
  }
  logger.log(
    videoObject,
    videoId,
    'scs',
    {
      ...videoObject,
      _id: videoId,
    },
    'addVideo'
  );
  try {
    const data = await apiClient.post(apiUrls.addVideo, {
      ...videoObject,
      _id: videoId,
    });
    logger.log(data, 'response-addVideo');
    dispatch(getVideoGalleryList('', '8881831836', 1, 100));
    return data;
  } catch (error) {
    logger.log(error, 'error-addVideo');
  }
};

const reportVideo = async (dataToSend, successCallback = () => {}) => {
  try {
    const { data } = await apiClient.post(apiUrls.editVideo, dataToSend);
    //dispatch({type: 'REFRESH_VIDEO_STATE', payload: 5});
    logger.log(data, dataToSend, 'response-reportVideo');
    successCallback();
  } catch (error) {
    logger.log(error, 'error-reportVideo');
  }
};

const deleteVideo = async (videoId, successCallback = () => {}) => {
  try {
    const { data } = await apiClient.get(apiUrls.deleteVideo(videoId));
    //dispatch({type: 'REFRESH_VIDEO_STATE', payload: 5});
    // dispatch(getVideoGalleryList(category, user_id, page, limit))
    logger.log(data, videoId, 'response-deleteVideo');
    successCallback();
  } catch (error) {
    logger.log(error, 'error-deleteVideo');
  }
};

export {
  deleteVideo,
  getCategoryList,
  getVideoGalleryList,
  addVideo,
  reportVideo,
};
