const styles = {
  textInput: {
    marginBottom: "20px",
    marginTop: "20px",
  },

  wrongDetails: {
    color: "red",
    marginLeft: "10px",
  },

  loginButton: {
    marginTop: 15,
    marginBottom: 15,
  },

  loginPassword: {
    marginTop: 15,
    backgroundColor: "white",
    width: 275,
    paddingLeft: "10px",
  },

  loginId: {
    backgroundColor: "white",
    paddingLeft: "10px",
    width: 275,
  },

  signup: {
    textDecoration: "none",
  },
};

export default styles;
