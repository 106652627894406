import apiUrls from "./apis";
import apiClient from "./api-client.js";
import logger from "../utils/logger";

const appendInAppNotification = async (
  dataToSend = {},
  successCallBack = () => {},
  failCallback = () => {}
) => {
  try {
    const { data } = await apiClient.post(
      apiUrls.appendInAppNotification,
      dataToSend
    );
    successCallBack();
    logger.log(data, dataToSend, "response-appendInAppNotification");
  } catch (error) {
    failCallback();
    logger.log(error, "error-appendInAppNotification");
  }
};

export { appendInAppNotification };
