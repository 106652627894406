import React, { useState, useEffect, useRef } from "react";
import MenuAppBar from "../../routes/header";
import GenericTable from "../data-table/index";
import Button from "@material-ui/core/Button";
import { getUnpairedUsers } from "./apis";

const UnPairedUsers = ({}) => {
  const loadMoreRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    currentPage: 0,
    limit: 20,
    totalCount: 0,
  });
  useEffect(() => {
    getUnpairedUsers(
      paginationData?.limit,
      paginationData?.currentPage + 1,
      (res) => {
        setData([...data, ...res?.docs]);
        setPaginationData({
          totalPages: res?.pages,
          currentPage: res?.page,
          limit: res?.limit,
          totalCount: res?.total,
        });
      }
    );
  }, [pageNumber]);

  return (
    <div style={{}}>
      <MenuAppBar heading="Paired Users" />

      <div style={{ marginTop: 100 }}>
        <GenericTable
          data={data}
          requiredFor="unpairedUsers"
          totalCount={paginationData?.totalCount}
        />
      </div>
      <div ref={loadMoreRef} style={{ marginTop: 0, marginBotton: 20 }}>
        <Button
          style={{ marginRight: 10, marginLeft: 10 }}
          variant="contained"
          color="primary"
          disabled={paginationData?.totalPages <= pageNumber ? true : false}
          onClick={() => {
            setPageNumber(pageNumber + 1);
          }}
        >
          Load more
        </Button>
      </div>
    </div>
  );
};

export default UnPairedUsers;
