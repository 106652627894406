import React, { Suspense, useEffect } from "react";
import Routes from "./routes/index";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Route, Switch, HashRouter, BrowserRouter } from "react-router-dom";
import LoaderOne from "./components/loaders/loader-one";
import Loader from "./utils/components/loader";
import { ReactNotifications } from 'react-notifications-component';

const App = () => {
  useEffect(()=>{
    console.log(document.getElementsByTagName('iframe'),'iframe tag');
    if(document.getElementsByTagName('iframe')[0])
      document.getElementsByTagName('iframe')[0].style.position = 'relative';
  },[])
  
  return (
    <div>
      <ReactNotifications />
      <Provider store={store}>
        <Suspense fallback={<LoaderOne loading={true} />}>
          <BrowserRouter basename="/genwe-portal/">
            <Switch>
              <Routes />
              <Loader />
              
            </Switch>
          </BrowserRouter>
        </Suspense>
      </Provider>
    </div>
  );
};
export default App;
