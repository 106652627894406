import apiUrls from "../../../apis/apis";
import apiClient from "../../../apis/api-client.js";
import axios from "axios";
import logger from "../../../utils/logger";

const saveConnect = async (dataToSave) => {
  try {
    const { data } = await apiClient.post(apiUrls.saveConnect, dataToSave);
    logger.log(data, "response-getCategoryList");
  } catch (error) {
    logger.log(error, "error-getCategoryList");
  }
};

const checkUsername = async (reqData, mobile) => {
  console.log(reqData, "reqData");
  try {
    const { data = {} } = await apiClient.get(
      apiUrls.checkValidUser +
        "?username=" +
        reqData +
        "&phone_number=" +
        mobile
    );
    console.log(data, "response-checkValidUser");
    return data;
  } catch (error) {
    console.log(error, "error-checkValidUser");
    return { error: error.response };
  }
};
export { saveConnect, checkUsername };
