import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import apiClient from "../../../../apis/api-client";
import images from "../../../../constants/images/index";
import { baseUrlWithoutSlash } from "../../../../apis/apis";

const TagsPicker = ({ title, name, value, setFieldValue, error }) => {
  const [newTagText, setnewTagText] = useState("");
  const [text, setText] = useState(value);
  const [addTagError, setaddTagError] = useState(undefined);
  const [tagSuggetions, setTagSuggetions] = useState([]);

  useEffect(() => {
    // getTagSuggestions((abc) => setTagSuggetions(abc));
    const getTags = async () => {
      try {
        const { data = {} } = await apiClient.get(
          `http://${baseUrlWithoutSlash}/service/v0/constants/interests`
        );
        console.log(data, value, "data-suggestions");
        setTagSuggetions(data?.interests);
      } catch (error) {
        console.log(error, "error-suggestions");
      }
    };
    getTags();
  }, []);

  const suggestionsToShow =
    !!newTagText &&
    (tagSuggetions.length > 0 ? tagSuggetions : []).filter((sug) => {
      return sug.toLowerCase().includes(newTagText.toLowerCase());
    });

  console.log(suggestionsToShow, "suggestions ss");
  const addTag = (tagContent = "") => {
    tagContent = tagContent.trim();
    var tagValidation = /^[ A-Za-z0-9/()]*$/;

    if (tagContent === "") {
      setaddTagError(
        "You need to type something in the space above before adding it to your list."
      );
      return;
    }
    if (text.includes(tagContent)) {
      setaddTagError("Already Added");
      return;
    }

    if (text.length > 6) {
      setaddTagError("You can make up to a maximum of 7 entries.");
      return;
    }

    if (!tagValidation.test(tagContent)) {
      setaddTagError("Please enter a valid subject");
      return;
    }
    if (tagContent.length > 30) {
      setaddTagError("Subject name too long");
      return;
    }

    console.log(tagContent, "tagContent");
    setaddTagError(undefined);

    setText([...text, tagContent]);

    setnewTagText("");
    setFieldValue(name, [...text, tagContent]);
  };

  const removeTag = (index) => {
    console.log("cskcbskjc", index);
    const newTags = text.filter((val, i) => index !== i);
    setText([...newTags]);
    setFieldValue(name, [...newTags]);
  };

  return (
    <>
      {title && <span style={{ fontSize: 15, color: "black" }}>{title}</span>}
      <br />
      <div
        style={{
          flexDirection: "row",
          marginHorizontal: 10,
          marginTop: 10,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", borderRadius: 16 }}
        >
          <input
            value={newTagText}
            style={{
              borderRadius: 3,
              borderWidth: 1,
              height: 30,
              width: 350,
              border: "2px solid lightgrey",
              fontSize: 13,
            }}
            placeholder="Enter here"
            onChange={(e) => setnewTagText(e.target.value)}
          />
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              marginTop: 41,
              width: 300,
              backgroundColor: "#ebebeb",
              maxHeight: 300,
              overflowY: "auto",
              paddingLeft: 20,
            }}
          >
            {suggestionsToShow &&
              suggestionsToShow?.map(
                (item, i) =>
                  !text.includes(item) && (
                    <div
                      onClick={() => {
                        addTag(item);
                      }}
                      style={{
                        borderBottom: "1px solid #a8a8a8",
                        cursor: "pointer",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 17,
                          color: "black",
                          paddingVertical: 4,
                          paddingHorizontal: 10,
                        }}
                      >
                        {item}
                      </p>
                    </div>
                  )
              )}
          </div>
          <div
            style={{
              alignSelf: "center",
              width: 60,
              height: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "orange",
              borderRadius: 50,
              marginLeft: 20,
              cursor: "pointer",
            }}
            onClick={() => addTag(newTagText)}
          >
            <div
              style={{
                color: "white",
                fontSize: 12,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Add
            </div>
          </div>
        </div>
        {(addTagError || error) && (
          <span
            style={{
              marginTop: 10,
              textAlign: "center",
              color: "red",
              fontSize: 12,
            }}
          >
            {addTagError}
            {error}
          </span>
        )}

        <div
          style={{
            display: "grid",
            marginTop: 10,
            marginBottom: 10,
            borderRadius: 3,
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 10,
            border: "2px solid lightgrey",
            fontSize: 13,
            maxWidth: 450,
            padding: "4px 8px 4px 8px",
          }}
        >
          {text &&
            text.map((item, index) => (
              <div
                onClick={() => removeTag(index)}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 5,
                  backgroundColor: "lightgrey",
                  padding: "4px 8px 4px 8px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    margin: 2,
                    marginRight: 10,
                    color: "black",
                  }}
                >
                  {item}
                </p>
                <img style={{ height: 10, width: 10 }} src={images.x} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default TagsPicker;
