import apiUrls from '../../apis/apis';
import logger from '../../utils/logger';
import apiClient from '../../apis/api-client';

const getVideoCommentList = (videoId, limit, page, showBlock = true) => async (
  dispatch
) => {
  logger.log(limit, page, 'number-getCategoryList');
  dispatch({ type: 'VIDEO_COMMENT_LIST_REQUEST' });
  try {
    const { data } = await apiClient.get(
      apiUrls.videoCommentList(videoId, limit, page, showBlock),
      {}
    );
    logger.log(data, videoId, 'response-getVideoCommentList');
    dispatch({ type: 'VIDEO_COMMENT_LIST_SUCCESS', payload: data });
  } catch (error) {
    dispatch({
      type: 'VIDEO_COMMENT_LIST_FAILURE',
      payload: error.response,
    });
    logger.log(error, 'error-getVideoCommentList');
  }
};

const addVideoComment = async (commentObject, setRefresher = () => {}) => {
  try {
    const { data } = await apiClient.post(
      apiUrls.addVideoComment,
      commentObject
    );
    // dispatch(getVideoCommentList(commentObject.video_id, 100, 1));
    logger.log(data, 'response-addVideoComment');
    setRefresher();
  } catch (error) {
    logger.log(error, 'error-addVideoComment');
  }
};

const deleteUserComment = (id, videoId) => async (dispatch) => {
  logger.log(id, videoId);
  try {
    const { data } = apiClient.get(apiUrls.deleteUserComment(id, videoId));
    logger.log(data, 'response-deleteVideoComment');
    dispatch(getVideoCommentList(videoId, 100, 1));
  } catch (error) {
    logger.log(error, 'error-deleteVideoComment');
  }
};
export { addVideoComment, getVideoCommentList, deleteUserComment };
