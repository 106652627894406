import _ from "lodash";
import pageLimits from "../../utils/page-limits";

const initialState = {};

const allUsers = (state = initialState, action) => {
  console.log(action.type, "action.type");
  switch (action.type) {
    case "GET_ALL_USERS_REQUEST": {
      return { ...state, loading: true };
    }

    case "GET_ALL_USERS_SUCCESS": {
      let items = state.allUsers?.docs?.slice(
        0,
        pageLimits.allUsers * (action.payload.page - 1)
      );
      return {
        ...state,
        allUsers:
          action.payload.page === 1
            ? action.payload
            : {
              page: action.payload.page,
              pages: action.payload.pages,
              // docs: [...items, ...action.payload.docs],
              docs: [...action.payload.docs],
              total: action.payload.total,
            },
        loading: false,
      };
    }
    case "GET_ALL_USERS_FAILURE": {
      return { ...state, loading: false };
    }
    case "GET_USER_REQUEST": {
      return { ...state, loading: true };
    }
    case "GET_USER_SUCCESS": {
      return { ...state, allUsers: { docs: [action.payload] }, loading: false };
    }
    case "GET_USER_FAILURE": {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};
export default allUsers;
