import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import SimpleModal from "../modals/type-one";
import { reportVideo } from "../videos/apis";
import { connect, useDispatch } from "react-redux";
import { addVideoComment } from "../comments/apis";
import { blockUser, unblockUser, deleteUser } from "../users/apis";
import { deleteVideo } from "../videos/apis";
import { deleteUserComment as deleteUserCommentAPI } from "../comments/apis";
import pageLimits from "../../utils/page-limits";
import { showLoader } from "../../utils/components/loader";
import { Box, Button } from "@material-ui/core";

const RenderActions = ({
  requiredFor,
  setPageNumber,
  deleteUserComment,
  row,
  blockPeer,
  toggleSideBar,
  showBlockPeer,
  refresher,
  setRefresher,
  setSearchAgain,
  searchAgain,
  index,
  approveUser,
  rejectUser,
  acceptNewUser,
  rejectNewUser
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const showDeleteIcon =
    requiredFor === "videos" ||
      requiredFor === "videoComments" ||
      requiredFor === "users"
      ? true
      : false;
  const showBlockPeerButton = showBlockPeer ? true : false;
  const showEdit =
    requiredFor === "call_logs" ||
      requiredFor === "individualScoringData" ||
      requiredFor === "webinars" ||
      requiredFor === "activity_response" ||
      requiredFor === "video_viewed" ||
      requiredFor === "qna_response" ||
      requiredFor === "connects" ||
      requiredFor === "connect" ||
      requiredFor === "allUsersCallLogs" ||
      requiredFor === "nonAttendeesForActivity" ||
      requiredFor === "allEnquiries" ||
      requiredFor === "downloads" ||
      requiredFor === "blockedVideoComments" ||
      requiredFor === "pairedUsers" ||
      requiredFor === "newRegistrations"
      ? false
      : true;
  const showReportVideo = requiredFor === "videos" ? true : false;
  const showBlockComment =
    requiredFor === "videoComments" || requiredFor === "blockedVideoComments"
      ? true
      : false;
  const showBlockUserFromComment = requiredFor === "users" ? true : false;
  return (
    <TableCell>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {showEdit && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => toggleSideBar(true, row, index)}
          >
            <EditIcon style={{ height: 20 }} />
          </div>
        )}
        {showDeleteIcon && (
          <>
            <SimpleModal
              isModalOpen={showModal}
              yesCallback={async () => {
                if (requiredFor === "videoComments") {
                  const page = Math.floor(index / pageLimits.videoComments) + 1;
                  await deleteUserComment(row._id, row.video_id);
                  setPageNumber(page);
                  setSearchAgain(searchAgain + 1);
                }
                if (requiredFor === "users") {
                  await deleteUser(row._id);
                  const page = Math.floor(index / pageLimits.allUsers) + 1;
                  setPageNumber(page);
                  setSearchAgain(searchAgain + 1);
                }
                if (requiredFor === "videos") {
                  const page = Math.floor(index / pageLimits.allVideos) + 1;
                  await deleteVideo(row._id);
                  setPageNumber(page);
                  setSearchAgain(searchAgain + 1);
                }
                setShowModal(false);
              }}
              cancelCallback={() => {
                setShowModal(false);
              }}
              heading="Warning"
              body={`Do you want to delete "${requiredFor === "videos" ? row?.title : row?.name
                }".`}
            />
            <div style={{ cursor: "pointer" }} onClick={() => setShowModal(true)}>
              <DeleteIcon style={{ height: 20 }} />
            </div>
          </>
        )}

      </div>

      {showBlockPeerButton && requiredFor !== "newRegistrations" && (
        <div
          style={{ cursor: "pointer" }}
          onClick={async () => {
            await blockPeer(
              row?.meta?.haveBlockedPeer ? "unblock" : "block",
              row
            );
            const page = Math.floor(index / pageLimits.allUsers) + 1;
            setPageNumber(page);
            setSearchAgain(searchAgain + 1);
          }}
        >
          {row?.meta?.haveBlockedPeer ? "Unblock Peer" : "Block Peer"}
        </div>
      )}

      {showBlockUserFromComment && (
        <div
          style={{ cursor: "pointer" }}
          onClick={async () => {
            showLoader(dispatch, true);
            if (row?.comments_blocked) {
              await unblockUser(row?._id);
            } else {
              await blockUser(row?._id);
            }

            setTimeout(() => {
              const page = Math.floor(index / pageLimits.allUsers) + 1;
              setPageNumber(page);
              setSearchAgain(searchAgain + 1);
              showLoader(dispatch, false);
            }, 9000);
          }}
        >
          {row?.comments_blocked
            ? "Unblock from Comment"
            : "Block from Comment"}
        </div>
      )}
      {showReportVideo && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            const page = Math.floor(index / pageLimits.allVideos) + 1;
            reportVideo(
              { ...row, blocked: row?.blocked ? false : true },
              () => {
                setPageNumber(page);
                setSearchAgain(searchAgain + 1);
              }
            );
          }}
        >
          {row?.blocked ? "Unblock Video" : "Block Video"}
        </div>
      )}

      {showBlockComment && (
        <div
          style={{ cursor: "pointer" }}
          onClick={async () => {
            const page = Math.floor(index / pageLimits.videoComments) + 1;
            await addVideoComment(
              { ...row, blocked: row?.blocked ? false : true },
              () => { }
            );
            setPageNumber(page);
            setSearchAgain(searchAgain + 1);
          }}
        >
          {row?.blocked ? "Unblock" : "Block"}
        </div>
      )}

      {requiredFor === "newRegistrations" && (
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            width: "50%",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "2px" }}
            onClick={() => acceptNewUser(row._id)}
          // disabled={!row.video}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "2px" }}
            onClick={() => rejectNewUser(row._id)}
          // disabled={!row.video}
          >
            Reject
          </Button>
        </Box>
      )}
    </TableCell>
  );
};

const actions = {
  deleteUserComment: deleteUserCommentAPI,
};

export default connect(null, actions)(RenderActions);
