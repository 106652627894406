import React, { lazy } from 'react';
import PairedUsers from '../components/pairedUsers';
import SendPoints from '../components/sendPoints';
import UnPairedUsers from '../components/unpairedUsers';
import Reports from '../reports';

const DiscountCouponsList = lazy(() =>
  import('../components/discount-coupons/list')
);
const CreateDiscountCoupon = lazy(() =>
  import('../components/discount-coupons/create')
);
const ComplaintsAgainstUser = lazy(() =>
  import('../components/ComplaintsAgainstUser')
);
const ChatLogs = lazy(() => import('../components/chatSummary'));
const AppDownloadsList = lazy(() => import('../reports/reportList/index'));
const WebinarResponses = lazy(() =>
  import('../components/webinars-attendees/index')
);
const CallDetails = lazy(() => import('../components/callDetails'));
const Downloads = lazy(() => import('../components/downloads'));
const NewRegistrations = lazy(() => import('../components/new-registrations'));
const AllEnquiries = lazy(() => import('../components/all-enquiries'));
const Home = lazy(() => import('../components/home/index'));
const Videos = lazy(() => import('../components/videos/index'));
const Connects = lazy(() => import('../components/connects/index'));
const Users = lazy(() => import('../components/users/index'));
const SuspendedUsers = lazy(() => import('../components/suspended-users/index'));
const Leads = lazy(() => import('../components/leads/index'));
const VideoComments = lazy(() => import('../components/comments/index'));
const Login = lazy(() => import('../components/login/index'));
const UserEngagement = lazy(() =>
  import('../components/user-engagement/index')
);
const ActivityResponse = lazy(() =>
  import('../components/activity-response/index')
);
const Activities = lazy(() =>
  import('../components/connects/activities/index')
);
const AllUsersCallLogs = lazy(() =>
  import('../components/all-users-call-logs/index')
);
const CoinsLedger = lazy(() => import('../components/coins-ledger/index'));
const AllQnas = lazy(() => import('../components/qnaListing/index'));
const QnaResponses = lazy(() => import('../components/qnaResponses'));
const UserWisePoints = lazy(() => import('../components/userWisePoints/index'));
const userWisePointsPeerDetails = lazy(() => import('../components/userWisePointsPeerDetails/index'));
const BlockedCommentsList = lazy(() =>
  import('../components/blockedCommentsList/index')
);
const VideoViewsList = lazy(() => import('../components/videoViewsList/index'));
const PairingHistory = lazy(() => import('../components/pairingHistory/index'));
const Subjects = lazy(() => import('../components/subjects/index'));
const PollQuestions = lazy(() => import('../components/pollCards/index'));
const ScoringMechanism = lazy(() => import('../components/Scoring-mechanism/'))

const nonLoginPages = [
  {
    name: 'Login',
    path: '/',
    view: Login,
    exact: false,
    showAtHome: false,
    showAtDrawer: false,
  },
];
const pages = [
  {
    name: 'Login',
    path: '/',
    view: Login,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Home',
    path: '/home',
    view: Home,
    exact: true,
    showAtHome: false,
    showAtDrawer: true,
  },
  // {
  //   name: "User Aquisition",
  //   path: "/user-aquisition",
  //   view: UserAquisition,
  //   exact: true,
  //   showAtHome: false,
  //   showAtDrawer: true,
  // },
  // {
  //   name: "Paired Users",
  //   path: "/users/paired",
  //   view: PairedUsers,
  //   exact: true,
  //   showAtHome: false,
  //   showAtDrawer: false,
  // },
  // {
  //   name: "Unpaired Users",
  //   path: "/users/unpaired",
  //   view: UnPairedUsers,
  //   exact: true,
  //   showAtHome: false,
  //   showAtDrawer: false,
  // },
  {
    name: 'App Downloads List',
    path: '/users/app/downloads/list',
    view: AppDownloadsList,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'All Videos',
    path: '/videos',
    view: Videos,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },

  {
    name: 'Call Details',
    path: '/call-details',
    view: CallDetails,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },

  {
    name: 'All Videos Comments',
    path: '/videos/comments/:videoId',
    view: VideoComments,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Connects',
    path: '/connects',
    view: Connects,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Activities List',
    path: '/connects/:connectId',
    view: Activities,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'User Request',
    path: '/user-request',
    view: NewRegistrations,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Users',
    path: '/users',
    view: Users,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Suspended Users',
    path: '/suspended-users',
    view: SuspendedUsers,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Leads',
    path: '/leads',
    view: Leads,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'All Enquiries',
    path: '/all-enquiries',
    view: AllEnquiries,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'User Engagement',
    path: '/user/engagement/:userId',
    view: UserEngagement,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Activity Response',
    path: '/activity-response/:activityId',
    view: ActivityResponse,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'All Users Call Logs',
    path: '/all-users-call-logs',
    view: AllUsersCallLogs,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Downloads',
    path: '/downloads',
    view: Downloads,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Webinar Responses',
    path: '/webinar-responses',
    view: WebinarResponses,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Coins Ledger',
    path: '/coins-ledger',
    view: CoinsLedger,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'All Questions',
    path: '/all-qnas',
    view: AllQnas,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Qna Responses',
    path: '/qna-responses/:questionId',
    view: QnaResponses,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'User Points',
    path: '/user-points',
    view: UserWisePoints,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'User Points2',
    path: '/user-points2',
    view: userWisePointsPeerDetails,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Blocked Comments',
    path: '/blocked-comments',
    view: BlockedCommentsList,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Video Views List',
    path: '/videos/video-views-list/:videoId',
    view: VideoViewsList,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Chat Logs',
    path: '/chat-logs',
    view: ChatLogs,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
  {
    name: 'Send Points',
    path: '/send-points',
    view: SendPoints,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },

  {
    name: 'All Reports',
    path: '/reports/:reportType',
    view: Reports,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Report',
    path: '/reports/:reportType/:reportName',
    view: AppDownloadsList,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Pairing History',
    path: '/pairing-history',
    view: PairingHistory,
    exact: true,
    showAtHome: true,
    showAtDrawer: false,
  },
  {
    name: 'Subjects',
    path: '/subjects',
    view: Subjects,
    exact: true,
    showAtHome: true,
    showAtDrawer: false,
  },
  {
    name: 'Poll Questions',
    path: '/poll-questions',
    view: PollQuestions,
    exact: true,
    showAtHome: true,
    showAtDrawer: false,
  },
  {
    name: 'Complaints Against User',
    path: '/complaints-against-user',
    view: ComplaintsAgainstUser,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Discount Coupons',
    path: '/discount-coupons',
    view: DiscountCouponsList,
    exact: true,
    showAtHome: true,
    showAtDrawer: false,
  },
  {
    name: 'Create Discount Coupon',
    path: '/discount-coupons/create',
    view: CreateDiscountCoupon,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Edit Discount Coupon',
    path: '/discount-coupons/edit/:id',
    view: CreateDiscountCoupon,
    exact: true,
    showAtHome: false,
    showAtDrawer: false,
  },
  {
    name: 'Scoring Mechanism',
    path: '/scoring-Mechanism',
    view: ScoringMechanism,
    exact: true,
    showAtHome: true,
    showAtDrawer: true,
  },
];

export default pages;
export { nonLoginPages };
