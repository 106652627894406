import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import OtherIcon from "@material-ui/icons/AcUnit";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import UsersIcon from "@material-ui/icons/Group";
import VideosIcon from "@material-ui/icons/VideoLibrary";
import ConnectsIcon from "@material-ui/icons/LocalActivity";
import DownloadsIcon from "@material-ui/icons/GetApp";
import CallLogIcon from "@material-ui/icons/Call";
import EnquiryIcon from "@material-ui/icons/Help";

import pages from "./pages";
import { NavLink, NavNavLink } from "react-router-dom";
import { logout } from "../utils/helpers";
import { useHistory } from "react-router-dom";

const renderIcon = (name, color = "") => {
  if (name === "All Videos") {
    return <VideosIcon style={{ color: color }} />;
  }
  if (name === "Connects") {
    return <ConnectsIcon style={{ color: color }} />;
  }
  if (name === "Users") {
    return <UsersIcon style={{ color: color }} />;
  }
  if (name === "Home") {
    return <HomeIcon style={{ color: color }} />;
  }
  if (name === "All Enquiries") {
    return <EnquiryIcon style={{ color: color }} />;
  }
  if (name === "Downloads") {
    return <DownloadsIcon style={{ color: color }} />;
  }
  if (name === "All Users Call Logs") {
    return <CallLogIcon style={{ color: color }} />;
  }
  return <OtherIcon style={{ color: color }} />;
};

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const MenuAppBar = ({ heading }) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = (value) => {
    setIsDrawerOpen(value);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List>
        {pages.map((page, index) => {
          if (page.showAtDrawer) {
            return (
              <NavLink to={page.path} style={{ textDecoration: "none" }}>
                <ListItem button key={index}>
                  <ListItemIcon>{renderIcon(page.name)}</ListItemIcon>
                  <ListItemText>{page.name}</ListItemText>
                </ListItem>
              </NavLink>
            );
          }
        })}
      </List>
      <Divider />
      <List>
        <ListItem button>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Logout"} onClick={logoutFunction} />
        </ListItem>
      </List>
    </div>
  );

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutFunction = () => {
    logout(() => history.push("/"));
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ position: "fixed", top: 0, left: 0, margin: 0 }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <div onClick={() => toggleDrawer(true)}>
              <MenuIcon />
            </div>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {heading}
          </Typography>

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={logoutFunction}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        anchor={"left"}
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </>
  );
};

export default MenuAppBar;
export { renderIcon };
