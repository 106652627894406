import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SET_LOADER } from "../../redux/actions";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Loader() {
  const classes = useStyles();

  const loading = useSelector((state) => state.commonReducer.loading);
  if (!loading) {
    return null;
  }
  return (
    <div
      className={classes.root}
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: "rgba(0,0,0,0.3)",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        // zIndex: 1000,
        // backgroundColor: "rgba(0,0,0,0.5)",
        // position: "absolute",
        // top: 0,
        // right: 0,
        // left: 0,
        // bottom: 0,
        // justifyContent: "center",
        // alignItems: "center",
        // display: "flex",
        // // display: "block",
        // //        height:600px;
        // overflow: "none",
        // backgroundAttachment: "fixed",
        // // background-position: center;
        // // background-repeat: no-repeat;
        // // background-size: cover;
      }}
    >
      <CircularProgress />
      {/* <CircularProgress color="secondary" /> */}
    </div>
  );
}

const showLoader = (dispatch, status) => {
  //status may be true or false
  dispatch({ type: SET_LOADER, payload: status });
};

export { showLoader };
