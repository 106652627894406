import React from "react";
import { Formik, Form, Field } from "formik";

const SimpleInput = ({ title, name, type, placeholder, component, error }) => {
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {title && <span style={{ fontSize: 15, color: "black" }}>{title}</span>}
      <br />
      <Field
        type={type}
        name={name}
        placeholder={placeholder}
        component={component}
        style={{
          borderRadius: 3,
          borderWidth: 1,
          height: 30,
          width: 450,
          border: "2px solid lightgrey",
          fontSize: 13,
        }}
      />
      <br />
      <span style={{ color: "red" }}>{error}</span>
    </div>
  );
};

export default SimpleInput;
