import apiUrls from "../../apis/apis";
import apiClient from "../../apis/api-client.js";
import logger from "../../utils/logger";

const getUnpairedUsers = async (page, limit, successCallBack = () => {}) => {
  try {
    const { data } = await apiClient.get(apiUrls.unpairedUsers(page, limit));

    logger.log(data, "response-getUnpairedUsers");
    successCallBack(data);
  } catch (error) {
    logger.log(error, "error-getUnpairedUsers");
  }
};

export { getUnpairedUsers };
