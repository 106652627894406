import React from 'react';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';
const SelectInput = ({ title, name, options }) => {
  console.log(options, 'options');
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {title && <span style={{ fontSize: 15, color: 'black' }}>{title}</span>}
      <br />
      <Field
        as='select'
        name={name}
        style={{
          borderRadius: 3,
          borderWidth: 1,
          height: 30,
          width: 450,
          border: '2px solid lightgrey',
          fontSize: 13,
        }}
      >
        {options.map((item, i) => (
          <option value={item.value}>{item.name}</option>
        ))}
      </Field>
      <br />
    </div>
  );
};

export default SelectInput;
