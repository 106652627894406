import React, { useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Formik, Form, Field } from "formik";
import Button from "@material-ui/core/Button";
import images from "../../../constants/images/index";
import SimpleInput from "./form-fields/simple-input";
import SelectInput from "./form-fields/select";
import ImagePicker from "./form-fields/image-picker";
import TagsPicker from "./form-fields/tags-picker";
import logger from "../../../utils/logger";
import {
  createProgram as createProgramAPI,
  createActivity,
} from "../../connects/apis";
import { updateProfileMultipleKeys as updateProfileMultipleKeysAPI } from "../../users/apis";
import { addVideo as addVideoAPI } from "../../videos/apis";
import { connect } from "react-redux";
import { addVideoComment } from "../../comments/apis";
import LoaderOne from "../../loaders/loader-one";
import { usersValidationSchema } from "./validations";
import pageLimits from "../../../utils/page-limits";

const SideBar = ({
  isDrawerOpen,
  setIsDrawerOpen,
  toggleSideBar,
  setDrawerData,
  data,
  setPageNumber,
  requiredFor,
  addVideo,
  videoCategories,
  createProgram,
  updateProfileMultipleKeys,
  setSearchAgain,
  searchAgain,
  refresher,
  setRefresher,
  index,
}) => {
  const [loading, setLoading] = useState(false);
  const toggleDrawer = (value) => {
    setIsDrawerOpen(value);
  };
  logger.log(data, "data");

  const videoCategoriesArray = videoCategories.map((item, i) => {
    return { name: item, value: item };
  });

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={isDrawerOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <div
        style={{
          width: 500,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <LoaderOne loading={loading} />
        ) : (
          <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={requiredFor === "users" && usersValidationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              logger.log(values, "data", requiredFor);
              if (requiredFor === "connects") {
                await createProgram(values);
              } else if (requiredFor === "connect") {
                await createActivity(values);
              } else if (requiredFor === "users") {
                const page = Math.floor(index / pageLimits.allUsers) + 1;
                await updateProfileMultipleKeys(values);
                setPageNumber(page);
                setSearchAgain(searchAgain + 1);
              } else if (requiredFor === "videos") {
                await addVideo(values);
                const page = Math.floor(index / pageLimits.allVideos) + 1;
                setPageNumber(page);
                setSearchAgain(searchAgain + 1);
              } else if (requiredFor === "videoComments") {
                await addVideoComment(values);
                const page = Math.floor(index / pageLimits.videoComments) + 1;
                setPageNumber(page);
                setSearchAgain(searchAgain + 1);
              } else {
                logger.log("No action");
              }
              setLoading(false);
              toggleDrawer(false);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <>
                <Form>
                  <div style={{ paddingBottom: 100 }}>
                    {values.avatar !== undefined && (
                      <ImagePicker
                        setFieldValue={setFieldValue}
                        title="Profile picture"
                        type="base64"
                        name="avatar"
                        file={values.avatar}
                        defaultImage={images.defaultUserImage}
                      />
                    )}
                    {values.name !== undefined && (
                      <SimpleInput
                        title="Name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        error={errors?.name}
                      />
                    )}
                    {values.achievement !== undefined && (
                      <SimpleInput
                        title="Achievements"
                        name="achievement"
                        type="text"
                        placeholder="Achievements"
                      />
                    )}
                    {values.participations !== undefined && (
                      <SimpleInput
                        title="Clubs and Team Participations"
                        name="participations"
                        type="text"
                        placeholder="Clubs and Team Participations"
                      />
                    )}
                    {values.nickname !== undefined && (
                      <SimpleInput
                        title="Nickname"
                        name="nickname"
                        type="text"
                        placeholder="Nickname"
                        error={errors?.nickname}
                      />
                    )}
                    {/* {values.inspirations !== undefined && (
                      <SimpleInput
                        title="Role Models"
                        name="inspirations"
                        type="text"
                        placeholder="Role Models"
                      />
                    )} */}

                    {values.goals !== undefined && (
                      <SimpleInput
                        title="Goals"
                        name="goals"
                        type="text"
                        placeholder="Goals"
                      />
                    )}

                    {values.inspirations !== undefined && (
                      <SimpleInput
                        title="Role Models"
                        name="inspirations"
                        type="text"
                        placeholder="Role Models"
                      />
                    )}

                    {values.expert_at !== undefined && (
                      <TagsPicker
                        title="I am good at..."
                        name="expert_at"
                        value={values.expert_at}
                        setFieldValue={setFieldValue}
                        error={errors?.expert_at}
                      />
                    )}
                    {values.preferred_partner_description !== undefined && (
                      <TagsPicker
                        title="I want to improve..."
                        name="preferred_partner_description"
                        value={values.preferred_partner_description}
                        setFieldValue={setFieldValue}
                      />
                    )}

                    {values.username !== undefined && (
                      <SimpleInput
                        title="Username"
                        name="username"
                        type="text"
                        placeholder="Username"
                        error={errors?.username}
                      />
                    )}
                    {values.city !== undefined && (
                      <SimpleInput
                        title="City"
                        name="city"
                        type="text"
                        placeholder="City"
                      />
                    )}
                    {values.school !== undefined && (
                      <SimpleInput
                        title="School"
                        name="school"
                        type="text"
                        placeholder="School"
                        error={errors?.school}
                      />
                    )}
                    {values.family !== undefined && (
                      <SimpleInput
                        title="Family"
                        name="family"
                        type="text"
                        placeholder="Family"
                      />
                    )}

                    {values.comment !== undefined && (
                      <SimpleInput
                        title="Comment"
                        name="comment"
                        type="text"
                        placeholder="Comment"
                      />
                    )}
                    {values.title !== undefined && (
                      <SimpleInput
                        title="title"
                        name="title"
                        type="text"
                        placeholder="title"
                      />
                    )}
                    {values.description !== undefined && (
                      <SimpleInput
                        title="Description"
                        name="description"
                        type="text"
                        component="textarea"
                        placeholder="description"
                      />
                    )}
                    {values.about !== undefined && (
                      <SimpleInput
                        title="about"
                        name="about"
                        type="text"
                        component="textarea"
                        placeholder="about"
                      />
                    )}

                    {values.points !== undefined && (
                      <SimpleInput
                        title="Points"
                        name="points"
                        type="text"
                        placeholder="Points"
                      />
                    )}

                    {values.category !== undefined && (
                      <SelectInput
                        title="Category"
                        name="category"
                        options={[
                          { name: "Normal Video", value: "" },
                          { name: "Youtube", value: "YOUTUBE" },
                        ]}
                        options={videoCategoriesArray}
                      />
                    )}

                    {values.in_app !== undefined && (
                      <SelectInput
                        title="Show on dashboard"
                        name="in_app"
                        options={[
                          { name: "True", value: true },
                          { name: "False", value: false },
                        ]}
                      />
                    )}

                    {values.thumbnail !== undefined && (
                      <SimpleInput
                        title="Thumbnail"
                        name="thumbnail"
                        type="text"
                        placeholder="Thumbnail"
                      />
                    )}
                    {values.icon !== undefined && (
                      <SimpleInput
                        title="Icon"
                        name="icon"
                        type="text"
                        placeholder="Icon Url"
                      />
                    )}
                    {values.type !== undefined && (
                      <SelectInput
                        title="Type"
                        name="type"
                        options={
                          requiredFor === "videos"
                            ? [
                                { name: "Normal Video", value: "" },
                                { name: "Youtube", value: "YOUTUBE" },
                              ]
                            : requiredFor === "connect"
                            ? [{ name: "Article", value: "ARTICLE" }]
                            : []
                        }
                      />
                    )}

                    {values.base_url !== undefined && (
                      <SimpleInput
                        title={
                          values.type === "YOUTUBE"
                            ? "Youtube video id"
                            : "Video URL"
                        }
                        name="base_url"
                        type="text"
                        placeholder={
                          values.type === "YOUTUBE"
                            ? "Youtube video id"
                            : "Video URL"
                        }
                      />
                    )}
                    {values.email !== undefined && (
                      <SimpleInput
                        title="email"
                        name="email"
                        type="text"
                        placeholder="email"
                      />
                    )}
                    {values.about_me !== undefined && (
                      <SimpleInput
                        title="About Me"
                        name="about_me"
                        type="text"
                        component="textarea"
                        placeholder="About Me"
                      />
                    )}
                    {values.gender !== undefined && (
                      <SelectInput
                        title="Gender"
                        name="gender"
                        options={[
                          { name: "Male", value: "male" },
                          { name: "Female", value: "female" },
                        ]}
                      />
                    )}
                    {values.tuition_fee !== undefined && (
                      <SelectInput
                        title="Tuition Fees"
                        name="tuition_fee"
                        options={[
                          {
                            name: "Less than INR 50,000 per year",
                            value: "Less than INR 50,000 per year",
                          },
                          {
                            name: "INR 50,000 to 1 lakh per year",
                            value: "INR 50,000 to 1 lakh per year",
                          },
                          {
                            name: "Greater than INR 1 lakh per year",
                            value: "Greater than INR 1 lakh per year",
                          },
                        ]}
                      />
                    )}
                    {values.class !== undefined && (
                      <SelectInput
                        title="Grade"
                        name="class"
                        options={[
                          {
                            name: "5th",
                            value: "5th",
                          },
                          { name: "6th", value: "6th" },
                          { name: "7th", value: "7th" },
                          { name: "8th", value: "8th" },
                          { name: "9th", value: "9th" },
                          { name: "10th", value: "10th" },
                          { name: "11th/FYJC", value: "11th/FYJC" },
                          { name: "12th/SYJC", value: "12th/SYJC" },
                          { name: "Other", value: "Other" },
                        ]}
                      />
                    )}
                  </div>
                  <Button
                    style={{
                      right: 0,
                      position: "fixed",
                      bottom: 0,
                      width: 517,
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                  {console.log(errors, "adcsdcsdcdsc")}
                </Form>
              </>
            )}
          </Formik>
        )}
      </div>
    </SwipeableDrawer>
  );
};

const actions = {
  addVideo: addVideoAPI,
  createProgram: createProgramAPI,
  updateProfileMultipleKeys: updateProfileMultipleKeysAPI,
};

const mapStateToProps = ({
  videoGallery: { category: videoCategories = [] },
}) => ({
  videoCategories,
});
export default connect(mapStateToProps, actions)(SideBar);
