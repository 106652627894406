import apiUrls from "../apis/apis";
import DownloadsIcon from "@material-ui/icons/GetApp";
import GroupIcon from "@material-ui/icons/Group";
import HomeIcon from "@material-ui/icons/Home";

import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import moment from "moment";
import { SET_FILTERS } from "../redux/actions";

const reportConfigs = {
  home: {
    name: "Home",
    path: "/home",
    Icon: () => <HomeIcon />,
  },
  userAquisition: {
    name: "User Aquisition",
    path: "/reports/userAquisition",
    Icon: () => <GroupIcon />,
    reports: {
      usersAppDownloadsList: {
        api: (query) => apiUrls.reports.appDownloadsList(query),
        summaryApi: (query) => apiUrls.reports.appDownloadsSummary(query),
        heading: "Users App Download List",
        path: `/reports/userAquisition/usersAppDownloadsList`,
        chart: true,
      },
      registeredUsersList: {
        api: (query) => apiUrls.reports.registeredUsersList(query),
        summaryApi: (query) => apiUrls.reports.registeredUsersSummary(query),
        heading: "Registered Users List",
        path: `/reports/userAquisition/registeredUsersList`,
        chart: true,
      },
      inductedUsersList: {
        api: (query) =>
          apiUrls.reports.inductedUsersList(
            `${query}&activity_id=welcome_to_genwe_activity_10`
          ),
        summaryApi: (query) =>
          apiUrls.reports.inductedUsersSummary(
            `${query}&activity_id=welcome_to_genwe_activity_10`
          ),
        heading: "Inducted Users List",
        path: `/reports/userAquisition/inductedUsersList`,
        chart: true,
      },
      pairedUsersList: {
        api: (query) => apiUrls.reports.pairedUsers(query),
        summaryApi: (query) => apiUrls.reports.pairedUsersSummary(query),
        heading: "Paired Users List",
        path: `/reports/userAquisition/pairedUsersList`,
        chart: true,
      },
      unpairedUsersList: {
        api: (query) => apiUrls.reports.unpairedUsers(query),
        summaryApi: (query) => apiUrls.reports.unpairedUsersSummary(query),
        heading: "Unpaired Users List",
        path: `/reports/userAquisition/unpairedUsersList`,
        chart: true,
      },
      chatSupportMessagesList: {
        heading: "User Details Report",
        chart: false,
        path: "/users",
       
      },
    },
  },
  activities: {
    name: "Activities",
    path: "/reports/activities",
    Icon: () => <LocalActivityIcon />,
    reports: {
      activityResponsesReport: {
        api: (query) => apiUrls.reports.activityResponseListReport(query),
        summaryApi: (query) =>
          apiUrls.reports.activityResponseListReportSummary(query),
        heading: "Activity Responses Report",
        path: `/reports/activities/activityResponsesReport`,
        chart: false,
      },
      activityCompletionStatusReport: {
        api: (query) => apiUrls.reports.activityCompletionStatusReport(query),
        // summaryApi: (query) =>
        //   apiUrls.reports.activityResponseListReportSummary(query),
        heading: "Activity Completion Status Report",
        path: `/reports/activities/activityCompletionStatusReport`,
        chart: false,
      },
    },
  },
  engagement: {
    name: "Engagement",
    path: "/reports/engagement",
    Icon: () => <TrendingUpIcon />,
    reports: {
      chatsListReport: {
        api: (query) => apiUrls.reports.chatsListReport(query),
        summaryApi: (query) => apiUrls.reports.chatsListReportSummary(query),
        heading: "User Chats List",
        path: `/reports/engagement/chatsListReport`,
        chart: true,
      },
      voiceCallListReport: {
        api: (query) => apiUrls.reports.callLogListReport(`${query}&type=CALL`),
        summaryApi: (query) =>
          apiUrls.reports.callLogListReportSummary(`${query}&type=CALL`),
        heading: "Voice Call Usage",
        path: `/reports/engagement/voiceCallListReport`,
        chart: true,
      },
      videoCallListReport: {
        api: (query) =>
          apiUrls.reports.callLogListReport(`${query}&type=VIDEO`),
        summaryApi: (query) =>
          apiUrls.reports.callLogListReportSummary(`${query}&type=VIDEO`),
        heading: "Video Call Usage",
        path: `/reports/engagement/videoCallListReport`,
        chart: true,
      },
      activePairedUsersReport: {
        api: (query) =>
          apiUrls.reports.userEngagementReport(
            `${query}&is_active=true&is_paired=true`
          ),
        summaryApi: (query) =>
          apiUrls.reports.userEngagementReportSummary(
            `${query}&is_active=true&is_paired=true`
          ),
        heading: "Active Users(Paired)",
        path: `/reports/engagement/activePairedUsersReport`,
        chart: true,
      },
      activeUnPairedUsersReport: {
        api: (query) =>
          apiUrls.reports.userEngagementReport(
            `${query}&is_active=true&is_paired=false`
          ),
        summaryApi: (query) =>
          apiUrls.reports.userEngagementReportSummary(
            `${query}&is_active=true&is_paired=false`
          ),
        heading: "Active Users(Individual)",
        path: `/reports/engagement/activeUnPairedUsersReport`,
        chart: true,
      },
      inactivePairedUsersReport: {
        api: (query) =>
          apiUrls.reports.userEngagementReport(
            `${query}&is_active=false&is_paired=true`
          ),
        summaryApi: (query) =>
          apiUrls.reports.userEngagementReportSummary(
            `${query}&is_active=false&is_paired=true`
          ),
        heading: "Inactive Users(Paired)",
        path: `/reports/engagement/inactivePairedUsersReport`,
        chart: true,
      },
      inactiveUnPairedUsersReport: {
        api: (query) =>
          apiUrls.reports.userEngagementReport(
            `${query}&is_active=false&is_paired=false`
          ),
        summaryApi: (query) =>
          apiUrls.reports.userEngagementReportSummary(
            `${query}&is_active=false&is_paired=false`
          ),
        heading: "Inactive Users(Individual)",
        path: `/reports/engagement/inactiveUnPairedUsersReport`,
        chart: true,
      },
    },
  },
  points: {
    name: "Points",
    path: "/reports/points",
    Icon: () => <LoyaltyIcon />,
    reports: {
      pointsReport: {
        api: (query) => apiUrls.reports.pointsLedgerReport(query),
        summaryApi: (query) => apiUrls.reports.pointsSummaryReport(query),
        heading: "Points Report",
        path: `/reports/points/pointsReport`,
        chart: true,
      },
    },
  },
  appMonitoring: {
    name: "App Monitoring",
    path: "/reports/appMonitoring",
    Icon: () => <MobileScreenShareIcon />,
    reports: {
      chatSupportMessagesList: {
        heading: "Crashlytics",
        chart: false,
        path: "/reports/appMonitoring",
        onClick: () => {
          window.open(
            "https://console.firebase.google.com/u/4/project/genwe-4a0c0/crashlytics/app/ios:com.whatscooking.genwe/issues?state=open&time=last-seven-days&type=all&tag=all",
            "_blank"
          );
        },
      },
      contactUsMessagesList: {
        heading: "Performance",
        chart: false,
        path: "/reports/appMonitoring",
        onClick: () => {
          window.open(
            "https://console.firebase.google.com/u/4/project/genwe-4a0c0/performance/app/ios:com.whatscooking.genwe/trends",
            "_blank"
          );
        },
      },
    },
  },
  support: {
    name: "Support",
    path: "/reports/support",
    Icon: () => <LiveHelpIcon />,
    reports: {
      chatSupportMessagesList: {
        api: (query) => apiUrls.reports.chatListSupport(query),
        summaryApi: (query) => apiUrls.reports.chatListSupportSummary(query),
        heading: "Chat Messages List",
        path: `/reports/support/chatSupportMessagesList`,
        chart: true,
      },
      contactUsMessagesList: {
        api: (query) => apiUrls.reports.contactUsListSupport(query),
        summaryApi: (query) =>
          apiUrls.reports.contactUsListSupportSummary(query),
        heading: "Contact Us Messages List",
        path: `/reports/support/contactUsMessagesList`,
        chart: true,
      },
    },
  },
};

const setDatesFromChart = (params) => {
  let format = "MM-DD-YYYY";
  const { dispatch, selectedPeriod, date, navigate = () => {} } = params;
  let startDate, endDate;

  if (selectedPeriod === "daily") {
    endDate = date.day; //moment().month(date.day, "DD").format(format);
    startDate = date.day; //moment().month(date.day, "DD").format(format);
  }

  if (selectedPeriod === "monthly") {
    endDate = moment(date["month"], "YYYY-MM").endOf("month").format(format);
    startDate = moment(date["month"], "YYYY-MM")
      .startOf("month")
      .format(format);
  }

  if (selectedPeriod === "weekly") {
    let year = date.week.split("-")[0];
    let week = parseInt(date.week.split("-")[1]);
    endDate = moment()
      .isoWeekYear(year)
      .isoWeek(week)
      .endOf("week")
      .add(1, "day");
    startDate = moment()
      .isoWeekYear(year)
      .isoWeek(week)
      .startOf("week")
      .add(1, "day");
    console.log(
      week,
      moment(endDate),
      new Date(startDate),
      date,
      "dcscvdvdfvdcasc asd"
    );
  }

  dispatch({
    type: SET_FILTERS,
    payload: {
      data: {
        listToDate: new Date(endDate),
        listFromDate: new Date(startDate),
      },
    },
  });
  navigate();
};

const sortDates = (array, key) => {
  console.log(array, key, "aschndsklcsdc");

  if (!array) {
    return [];
  }
  if (key === "month" || key === "day") {
    return array.sort(function (a, b) {
      return new Date(a[key]) - new Date(b[key]);
    });
  }

  if (key === "week") {
    return array.sort(function (a, b) {
      console.log(
        b.week,
        new Date(moment(b.week, "YYYY-w").format("MM-DD-YYYY")),
        "cklsdncsdkcnsdlcdscdscsdc"
      );
      return (
        new Date(moment(a.week, "YYYY-w").format("MM-DD-YYYY")) -
        new Date(moment(b.week, "YYYY-w").format("MM-DD-YYYY"))
      );
      return parseInt(b.week.split("-")[1]) - parseInt(a.week.split("-")[1]);
    });

    return array.sort(function (a, b) {
      return parseInt(b.week.split("-")[1]) - parseInt(a.week.split("-")[1]);
    });
  }
  // return array.sort(function (a, b) {
  //   return parseFloat(a[key]) - parseFloat(b[key]);
  // });
};
export default reportConfigs;
export { setDatesFromChart, sortDates };
