import React, { useState, useEffect, useRef } from "react";
import apiUrls from "../../apis/apis";
import apiClient from "../../apis/api-client.js";
import logger from "../../utils/logger";
import { appendInAppNotification } from "../../apis";



const sendPoints = async (
  dataToSend = {},
  type,
  successCallBack = () => {},
  setError = () => {}
) => {
  try {
    const { data } = await apiClient.post(
      type === "Webinar"
        ? apiUrls.sendPointsWebinar
        : apiUrls.sendPointsMultiple,
      {
        ...dataToSend,
        title:
          type === "Webinar"
            ? `Webinar: ${dataToSend?.title}`
            : `Bonus: ${dataToSend?.title}`,
      }
    );
    successCallBack();
    appendInAppNotification({
      users: dataToSend?.users,
      notification: {
        // id: "test",
        identifier:
          type === "Webinar"
            ? `${dataToSend?.title} Congratulations! You have earned ${dataToSend?.points} points for attending the webinar: ${dataToSend?.title}!`
            : `${dataToSend?.title}  Congratulations! You have earned ${dataToSend?.points} bonus points for ${dataToSend?.title}!`,
        icon_url:
          "https://genweappassets.s3.ap-south-1.amazonaws.com/loading+icon.png",
        thumbnail_url:
          "https://genweappassets.s3.ap-south-1.amazonaws.com/loading+icon.png",
        //dismissed: false,
        text:
          type === "Webinar"
            ? `Congratulations! You have earned ${dataToSend?.points} points for attending the webinar: ${dataToSend?.title}!`
            : `Congratulations! You have earned ${dataToSend?.points} bonus points for ${dataToSend?.title}!`,
        // user_id: "917262098263",
        // reference: "jan_webinar||918881831836",
        // cta: [
        //   {
        //     text: "Confirm Now",
        //     styles: {
        //       backgroundColor: "orange",
        //       fontColor: "white",
        //       fontWeight: "300",
        //     },
        //     navigateTo: "Home",
        //     type: "Home",
        //   },
        // ],
      },
    });
    logger.log(data, "response-sendPoints");
  } catch (error) {
    logger.log(error, "error-sendPoints");
  }
};

export { sendPoints };
