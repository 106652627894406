import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import reportConfigs from "./utils";

const ReportsSideBar = () => {
  return (
    <ProSidebar width={200} image="https://picsum.photos/200">
      <Menu iconShape="square">
        {Object.keys(reportConfigs).map((item, i) => {
          const { path, name, Icon } = reportConfigs[item];
          return (
            <MenuItem icon={<Icon />}>
              <Link to={path}>{name}</Link>
            </MenuItem>
          );
        })}
      </Menu>
    </ProSidebar>
  );
};

export default ReportsSideBar;
