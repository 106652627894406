import React, { useEffect } from "react";
import DatePicker from "react-date-picker";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { SET_ALL_SCHOOLS, SET_FILTERS } from "../redux/actions";
import moment from "moment";
import { getAllSchools } from "./apis";
import { getConnects } from "../components/connects/apis";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const periodicals = [
  { name: "Daily", value: "daily" },
  { name: "Weekly", value: "weekly" },
  { name: "Monthly", value: "monthly" },
];

const pointTypes = [
  { name: "Chat", value: "CHAT" },
  { name: "Voice call", value: "VOICECALL" },
  { name: "Video call", value: "VIDEOCALL" },
  { name: "Webinar", value: "WEBINAR" },
  { name: "Bonus", value: "BONUS" },
  { name: "Activity", value: "ACTIVITY" },
  { name: "Video", value: "VIDEO" },
];

const Filters = () => {
  const { reportType } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    reports: { allSchools = [] } = {},
    filters: {
      selectedActivity,
      selectedGroup = "All",
      toDate,
      fromDate,
      selectedPeriod,
      selectedPointsType = "",
    } = {},
  } = useSelector((state) => state.commonReducer);
  const programs = useSelector(
    (state) => state.connects?.activitySubjects || []
  );
  useEffect(() => {
    getAllSchools((data) => {
      dispatch({
        type: SET_ALL_SCHOOLS,
        payload: { allSchools: ["All", ...data?.result] },
      });
    });
    dispatch(getConnects("919999999991"));
  }, []);
  console.log(programs, "programsprogramsprogramsprograms");
  return (
    <div style={{ flex: 1, flexDirection: "column" }}>
      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
        }}
      >
        <span>From date: </span>
        <DatePicker
          onChange={(e) => {
            dispatch({
              type: SET_FILTERS,
              payload: { data: { fromDate: e } },
            });
          }}
          value={fromDate}
          // minDate={new Date("02-01-2020")}
          minDate={new Date(moment(toDate).subtract(1, "years"))}
          maxDate={new Date()}
        />

        <span style={{ marginLeft: 20 }}>To date: </span>
        <DatePicker
          onChange={(e) => {
            dispatch({
              type: SET_FILTERS,
              payload: { data: { toDate: e } },
            });
          }}
          maxDate={new Date()}
          value={toDate || new Date()}
        />

        {allSchools?.length > 0 ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              textFieldProps={{ InputLabelProps: { shrink: true } }}
              htmlFor="outlined-age-native-simple"
              // shrink={true}
            >
              Groups
            </InputLabel>

            <Select
              native
              value={selectedGroup}
              onChange={(e) => {
                dispatch({
                  type: SET_FILTERS,
                  payload: { data: { selectedGroup: e.target.value } },
                });
              }}
              label="Groups"
              inputProps={{
                name: "Groups",
              }}
            >
              {allSchools.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {reportType === "activities" && programs?.length > 0 ? (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Activities
              </InputLabel>

              <Select
                native
                value={selectedActivity}
                onChange={(e) => {
                  dispatch({
                    type: SET_FILTERS,
                    payload: {
                      data: {
                        selectedActivity:
                          e.target.value === "All" ? "" : e.target.value,
                      },
                    },
                  });
                }}
                label="Activities"
                inputProps={{
                  name: "Activities",
                }}
              >
                <option key={"0"} value={"All"}>
                  All
                </option>
                {programs.map((item, index) => (
                  <option key={index + 1} value={item?._id}>
                    {item.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        ) : null}

        {reportType === "points" ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Points Type
            </InputLabel>

            <Select
              native
              value={selectedPointsType}
              onChange={(e) => {
                dispatch({
                  type: SET_FILTERS,
                  payload: {
                    data: {
                      selectedPointsType:
                        e.target.value === "All" ? "" : e.target.value,
                    },
                  },
                });
              }}
              label="Points Type"
              inputProps={{
                name: "Points Type",
              }}
            >
              <option key={"0"} value={"All"}>
                All
              </option>
              {pointTypes.map((item, index) => (
                <option key={index + 1} value={item?.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {reportType === "activities" ? null : (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Periodicals
            </InputLabel>

            <Select
              native
              value={selectedPeriod}
              onChange={(e) => {
                dispatch({
                  type: SET_FILTERS,
                  payload: { data: { selectedPeriod: e.target.value } },
                });
              }}
              label="Periodicals"
              inputProps={{
                name: "Periodicals",
              }}
            >
              {periodicals.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default Filters;
