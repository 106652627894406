import apiUrls from "../../apis/apis";
import logger from "../../utils/logger";
import apiClient from "../../apis/api-client";

const getAllUserAndPeerPoints = async (
  page,
  limit,
  setData,
  setPageNumber,
  setLoading,
  category,
  order,
  setCategory,
  setOrder
  ) => {
    logger.log(limit, page, "number-getUserWisePoints");
  setLoading(true);
  try {
    const  {data}  = await apiClient.get(
      'https://genwescoring.thegenwe.com/dashboard/getAllUserAndPeerPoints')
      // .then((res)=>{
      //   console.log(res);
      //   setLoading(false);
      // }).catch((err) => {
      //   console.log(err);
      //   setLoading(false);
      // });
    logger.log(data, "response-getUserWisePoints");
    logger.log(data[1]?.user?.points[0]?.total,data[1]?.peer?.points[0]?.total, "TOTAL POINTS" );
    data?setLoading(false):setLoading(true);
    const newdata = data.length ? data.map((item) => {
      return ({
        userName: item?.user?.name ? item?.user?.name : '-',
        userId: item?.user?.points[0]?._id?.user_id,
        userPoint: item?.user?.points[0]?.total ? item?.user?.points[0]?.total : '0',
        peerName: item?.peer?.name ? item?.peer?.name : '-',
        peerId: item?.peer?.points[0]?._id?.user_id ? item?.peer?.points[0]?._id?.user_id : '-',
        peerPoint: item?.peer?.points[0]?.total ? item?.peer?.points[0]?.total : '0',
        totalPoint:  Number(item?.user?.points[0]?.total??'0') + Number(item?.peer?.points[0]?.total??'0') ? Number(item?.user?.points[0]?.total??'0') + Number(item?.peer?.points[0]?.total??'0') :'0'
      }

      )
    }) : []

let prevOrder="";
    if(order && category){
      if(order === 'ASC' && category === 'userPoint'){
        const newData1 = newdata.sort((a, b) => {
          const pointsA = a?.userPoint;
          const pointsB = b?.userPoint;
          return  pointsB-pointsA ;
        });
        setData(newData1);
        prevOrder=order;
      }
       if (order==='DESC' && category === 'userPoint'){
        const newData1 = newdata.sort((a, b) => {
          const pointsA = a?.userPoint;
          const pointsB = b?.userPoint;
          return pointsA - pointsB;
        });
        setData(newData1);
        prevOrder=order;
      }

      if(order === 'ASC' && category === 'peerPoint'){
        const newData1 = newdata.sort((a, b) => {
          const pointsA = a?.peerPoint;
          const pointsB = b?.peerPoint;
          return  pointsB-pointsA ;
        });
        setData(newData1);
        prevOrder=order;
      }
       if (order==='DESC' && category === 'peerPoint'){
        const newData1 = newdata.sort((a, b) => {
          const pointsA = a?.peerPoint;
          const pointsB = b?.peerPoint;
          return pointsA - pointsB;
        });
        setData(newData1);
        prevOrder=order;
      }
      if(order === 'ASC' && category === 'totalPoint'){
        const newData1 = newdata.sort((a, b) => {
          const pointsA = a?.totalPoint;
          const pointsB = b?.totalPoint;
          return  pointsB-pointsA ;
        });
        setData(newData1);
        prevOrder=order;
      }
       if (order==='DESC' && category === 'totalPoint'){
        const newData1 = newdata.sort((a, b) => {
          const pointsA = a?.totalPoint;
          const pointsB = b?.totalPoint;
          return pointsA - pointsB;
        });
        setData(newData1);
        prevOrder=order;
      }
    }else{
      setData(newdata);
    }

    

    logger.log(newdata, "newdata");
  } catch (error) {
    logger.log(error, "error-getUserWisePoints");
  }
};

export { getAllUserAndPeerPoints };
