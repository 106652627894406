import * as Yup from "yup";
import { checkUsername } from "./apis";

const usersValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, `Your name must contain at least 3 characters.`)
    .matches(/^[a-z\s]+$/i, "Only alphabetic characters allowed")
    .required("Name is required"),
  school: Yup.string()
    .matches(/^[ a-zA-Z0-9]+$/, "Please enter the name of a school.")
    .required("Please enter the name of a school."),
  nickname: Yup.string()
    .min(3, (obj) => `A minimum of 3 characters is required`)
    .test(
      "test-name",
      "Nickname already taken",
      async (value = "", context) => {
        const abc = await checkUsername(
          value.toLowerCase(),
          `${context?.parent?.phone_number}`
        );
        console.log("adcsdcsdcdsc", abc, context?.parent?.phone_number);
        return abc.valid;
      }
    )
    .required("Nickname is required"),
  username: Yup.string()
    .min(3, (obj) => `A minimum of 3 characters is required`)
    .test(
      "test-name",
      "Nickname already taken",
      async (value = "", context) => {
        const abc = await checkUsername(
          value.toLowerCase(),
          context?.parent?.phone_number
        );
        return abc.valid;
      }
    )
    .required("Nickname is required"),
  expert_at: Yup.array()
    .of(Yup.string().required("s"))
    .min(1, "Please enter atleast one subject"),
  preferred_partner_description: Yup.array()
    .of(Yup.string().required("s"))
    .min(1, "Please enter atleast one subject"),
});

export { usersValidationSchema };
