import React, { useEffect, useState } from "react";
import Item from "./item";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
import { isLogin } from "../utils/helpers";
import FiltersWrapper from "../wrappers/filters";
import reportConfigs from "./utils";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
  },
}));

const Reports = () => {
  let { reportType } = useParams();
  let { reports, name } = reportConfigs[reportType];
  const classes = useStyles();

  const login = isLogin();
  if (!login) {
    return <Redirect to={`/`} />;
  }

  return (
    <FiltersWrapper heading={name}>
      <Grid container className={classes.root} spacing={20} justify="center">
        {Object.keys(reports).map((item) => {
          return (
            <Grid item lg={6}>
              <Item report={reports[item]} />
            </Grid>
          );
        })}
      </Grid>
    </FiltersWrapper>
  );
};

export default Reports;
