import React from "react";
import moment from "moment";
import images from "../../constants/images/index";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import _ from "lodash";
import { isObject } from "formik";
import AllRegistrationsVideoModal from "../modals/allRegistrationsVideoModal";

const RenderTableCell = ({ item, row }) => {
  const {
    Component = () => {
      return null;
    },
  } = item;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (item?.name === "Thumbnail") {
    return (
      <TableCell component="th" scope="row">
        <img
          src={
            item.type === "base64"
              ? row[item?.keyName]
                ? `data:image/png;base64,${row[item?.keyName]}`
                : images.defaultUserImage
              : row[item?.keyName]
          }
          style={{ height: 25, width: 25 }}
        />
      </TableCell>
    );
  }

  if (item.name === "Peer Id") {
    return (
      <TableCell component="th" scope="row">
        {row["chats"] &&
          row["chats"]["channels"][1] &&
          row["chats"]["channels"][1]["buddy_detail"]["_id"]}
      </TableCell>
    );
  }

  if (item.type === "peerDetails") {
    return (
      <TableCell component="th" scope="row">
        {row["chats"] &&
          row["chats"]["channels"][1] &&
          row["chats"]["channels"][1]["buddy_detail"][item?.keyName]}
      </TableCell>
    );
  }

  if (item.type === "nestedKey") {
    let res = item.keyName.split(".");
    let a = row;
    _.times(res.length, (i) => {
      console.log(a, res, i, "klsdhcnklsncklsdc");
      a = a && a[res[i]];
    });
    if (item.name.search("UserId") !== -1) {
      return (
        <TableCell component="th" scope="row">
          <Link to={`/user/engagement/${a}`}>{a}</Link>
        </TableCell>
      );
    } else if (item.name.search("date") !== -1) {
      return (
        <TableCell component="th" scope="row">
          {moment(a).format("MM/DD/YYYY hh:mm:ss A")}
        </TableCell>
      );
    } else {
      return (
        <TableCell component="th" scope="row">
          {a}
        </TableCell>
      );
    }
  }

  if (item.type === "stringifyJson") {
    console.log(row[item.keyName], "row[item.keyName]");
    // return null;
    if (isObject(row[item.keyName])) {
      return (
        <TableCell component="th" scope="row">
          {JSON.stringify(row[item.keyName])}
        </TableCell>
      );
    } else {
      return null;
    }
  }
  if (item.type === "object") {
    var decimal = /^[-+]?[0-9]+\.[0-9]+$/;

    return (
      <TableCell component="th" scope="row">
        {row &&
          row[item?.keyName] &&
          Object.keys(row[item?.keyName]).map((key, i) => {
            return (
              <div>
                {key}:{" "}
                {typeof row[item.keyName][key] === "number"
                  ? row[item.keyName][key].toFixed(0)
                  : row[item.keyName][key]}
              </div>
            );
          })}
      </TableCell>
    );
  }

  if (item.type === "date" && row[item.keyName]) {
    return (
      <TableCell component="th" scope="row">
        {moment(row[item.keyName]).format("DD/MM/YYYY hh:mm:ss A")}
      </TableCell>
    );
  }

  if (item.type === "onlyDate" && row[item.keyName]) {
    return (
      <TableCell component="th" scope="row">
        {moment(row[item.keyName]).format("DD/MM/YYYY")}
      </TableCell>
    );
  }

  if (item.type === "duration") {
    let seconds = "-";
    if (row[item.keyName1] && row[item.keyName2]) {
      var now = moment(row[item.keyName2]); //todays date
      var end = moment(row[item.keyName1]); // another date
      var duration = moment.duration(now.diff(end));
      seconds = duration.asSeconds();
    }
    return (
      <TableCell component="th" scope="row">
        {seconds === "-" ? seconds : parseInt(seconds)}
      </TableCell>
    );
  }
  if (item.type === "link") {
    return (
      <TableCell component="th" scope="row">
        <a target={"_blank"} href={row[item.keyName]}>
          Click here
        </a>
      </TableCell>
    );
  }

  if (item.type === "bool") {
    return (
      <TableCell component="th" scope="row">
        {`${row[item.keyName]}`}
      </TableCell>
    );
  }

  if (item.type === "array") {
    return (
      <TableCell component="th" scope="row">
        {row[item.keyName].map((item, i) => {
          if (i === 0) {
            return item;
          } else {
            return `, ${item}`;
          }
        })}
      </TableCell>
    );
  }

  if (item.name === "UserId") {
    return (
      <TableCell component="th" scope="row">
        <Link to={`/user/engagement/${row[item?.keyName]}`}>
          {row[item?.keyName]}
        </Link>
      </TableCell>
    );
  }
  if (item.type === "user") {
    return (
      <TableCell component="th" scope="row">
        <Link to={`/user/engagement/${row[item?.keyName]}`}>
          {row[item?.keyName]}
        </Link>
      </TableCell>
    );
  }

  if (item.type === "custom") {
    return <Component data={row} />;
  }

  if (item?.name == "Name") {
    if (item?.keyNamesAsArray?.length) {
      return (
        <TableCell component="th" scope="row">
          {item?.keyNamesAsArray
            .map((_key) => {
              console.log(row[_key], "Yooo");
              return row[_key] ?? "HT";
            })
            .join(", ")}
        </TableCell>
      );
    }
  }
  if (item.type === "video") {
    return (
      <>
        {row && row.video ? (
          <TableCell
            component="th"
            scope="row"
            onClick={handleOpen}
            style={{ cursor: "pointer" }}
          >
            Video
          </TableCell>
        ) : (
          <TableCell component="th" scope="row">
            No Video Available
          </TableCell>
        )}
        <AllRegistrationsVideoModal
          open={open}
          handleClose={handleClose}
          data={row}
        />
      </>
    );
  }
  return (
    <TableCell component="th" scope="row">
      {row[item?.keyName] || item?.defaultValue || "N/A"}
    </TableCell>
  );
};

export default RenderTableCell;
