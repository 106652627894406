import pageLimits from "../../utils/page-limits";

const initialState = {};

const videoComments = (state = initialState, action) => {
  console.log(action.type, "action.type");
  switch (action.type) {
    case "VIDEO_COMMENT_LIST_REQUEST": {
      return { ...state, loading: true };
    }
    case "VIDEO_COMMENT_LIST_SUCCESS": {
      let items = state.videoComments?.docs?.slice(
        0,
        pageLimits.videoComments * (action.payload.page - 1)
      );
      return {
        ...state,
        videoComments:
          action.payload.page === 1
            ? action.payload
            : {
                // ...action.payload,
                page: action.payload.page,
                pages: action.payload.pages,
                docs: [...items, ...action.payload.docs],
              },
        loading: false,
      };
    }
    case "VIDEO_COMMENT_LIST_FAILURE": {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};
export default videoComments;
