import apiUrls from "../../apis/apis";
import apiClient from "../../apis/api-client.js";
import logger from "../../utils/logger";

const getDataFromApi = async (apiUrl, successCallBack = () => {}) => {
  try {
    const { data } = await apiClient.get(apiUrl);
    logger.log(data, "response-getDataFromApi");
    if (data?.status === "success") {
      successCallBack(data?.result);
    } else {
      if (data.docs) {
        successCallBack(data);
      }
    }
  } catch (error) {
    logger.log(error, "error-getDataFromApi");
  }
};

export { getDataFromApi };
