const pageLimits = {
  allUsers: 30,
  allVideos: 30,
  videoComments: 60,
  coinsLedger: 100,
  allQnas: 10,
  qnaResponses: 3,
  userEngagement: 30,
  complaintsAgainstUser: 30
};

export default pageLimits;
