import apiUrls from "../../apis/apis";
import logger from "../../utils/logger";
import apiClient from "../../apis/api-client";
import { appendInAppNotification } from "../../apis";
import { addUserData, addUserEvent } from "../../apis/moengage";
import { store } from '../../redux/store';

const genstat = async (mobileNo, setData = () => { }) => {
  // dispatch({ type: 'GET_USER_REQUEST' });
  try {
    const { data = {} } = await apiClient.get(
      apiUrls.genstat + "?id=" + mobileNo
    );
    logger.log(data, "data-GenStatDashboard");
    setData(data);
    return data;
  } catch (error) {
    logger.log(error, "error-GenStatDashboard");
    // dispatch({ type: 'GET_USER_FAILURE' });
  }
};

const getUsers =
  (
    limit,
    page,
    setLoading,
    phoneNumber,
    gender,
    username,
    name,
    email,
    paired,
    priveleged,
    underPriveleged,
    ageRange,
    blocked,
    verificationStatus
  ) =>
    async (dispatch) => {
      dispatch({ type: "GET_ALL_USERS_REQUEST" });
      logger.log(
        phoneNumber,
        gender,
        username,
        name,
        email,
        priveleged,
        underPriveleged,
        ageRange,
        blocked,
        verificationStatus,
        "getUsers"
      );
      try {
        const { data = {} } = await apiClient.get(
          apiUrls.getAllUsers(
            limit,
            page,
            phoneNumber,
            gender,
            username,
            name,
            email,
            paired,
            priveleged,
            underPriveleged,
            ageRange?.min,
            ageRange?.max,
            blocked, verificationStatus
          )
        );
        logger.log(data, "data-getUsers");
        dispatch({ type: "GET_ALL_USERS_SUCCESS", payload: data });
        setLoading(false);
      } catch (error) {
        logger.log(error, "error-getUsers");
        dispatch({ type: "GET_ALL_USERS_FAILURE" });
        setLoading(false);
      }
    };

const updateProfileKey = (mobileNo, key, value) => async (dispatch) => {
  logger.log(mobileNo, key, value, "mobileNo, key, value-updateProfile");
  try {
    const { data = {} } = await apiClient.post(apiUrls.updateProfileKey, {
      _id: mobileNo,
      [key]: value,
    });
    logger.log(data, "response-updateProfile");
    // dispatch(genstat(mobileNo));
    return data;
  } catch (error) {
    logger.log(error, "error-updateProfile");
    return { error: error.response };
  }
};

const updateProfileMultipleKeys = (keys) => async (dispatch) => {
  logger.log(keys, "keys");
  try {
    const { data = {} } = await apiClient.post(apiUrls.addUser, keys);
    logger.log(data, "response-updateProfile");

    return data;
  } catch (error) {
    logger.log(error, "error-updateProfile");
  }
};

const pairUser = async (mobileNo1, mobileNo2, reason, setMessage, setError) => {
  logger.log(mobileNo1, mobileNo2, "mobileNo pairUser");
  try {
    logger.log(mobileNo1, mobileNo2, "mobileNo pairUser");
    const data = await apiClient.post(apiUrls.pairUsers, {
      user1: mobileNo1,
      user2: mobileNo2,
      points: 200,
      pair_reason: reason,
    });
    logger.log(data, "response-pairUser");

    const [user1, user2] = await Promise.all([
      genstat(mobileNo1),
      genstat(mobileNo2),

    ]);

    const [user1Updated, user2Updated] = await Promise.all([
      store.dispatch(updateProfileMultipleKeys({ _id: mobileNo1, meta: { ...user1?.meta, hideInAppCard: false } })),
      store.dispatch(updateProfileMultipleKeys({ _id: mobileNo2, meta: { ...user1?.meta, hideInAppCard: false } })),
    ]);

    addUserData({
      type: "customer",
      customer_id: mobileNo1,
      attributes: {
        pairing_status: "paired",
        last_pairing_date: new Date(),
        peer: mobileNo2,
        peer_name: user2?.name,
      },
    });
    addUserData({
      type: "customer",
      customer_id: mobileNo2,
      attributes: {
        pairing_status: "paired",
        last_pairing_date: new Date(),
        peer: mobileNo1,
        peer_name: user1?.name,
      },
    });
    addUserEvent({
      type: "event",
      customer_id: mobileNo2,
      actions: [{
        "action": "user_paired",
        "attributes": {
          pairing_status: "paired",
          last_pairing_date: new Date(),
          peer: mobileNo1,
          peer_name: user1?.name,
          reason
        },
      }],
    })
    addUserEvent({
      type: "event",
      customer_id: mobileNo1,
      actions: [{
        "action": "user_paired",
        "attributes": {
          pairing_status: "paired",
          last_pairing_date: new Date(),
          peer: mobileNo2,
          peer_name: user2?.name,
          reason
        },
      }],
    })
    setMessage("Successfully paired");
    appendInAppNotification({
      users: [mobileNo1, mobileNo2],
      notification: {
        identifier: `${mobileNo2} ${mobileNo1}`,
        icon_url:
          "https://genweappassets.s3.ap-south-1.amazonaws.com/loading+icon.png",
        thumbnail_url:
          "https://genweappassets.s3.ap-south-1.amazonaws.com/loading+icon.png",
        text: `Congratulations! You have successfully paired with your GenWE Peer and earned 200 points!`,
      },
    });
    return data;
  } catch (error) {
    logger.log(error.response, error.response?.data, "error-pairUser");

    const { data: { error: { error: errorToShow, message = {} } = {} } = {} } =
      error.response;
    if (Object.keys(error.response?.data?.error).length === 0) {
      appendInAppNotification({
        users: [mobileNo1, mobileNo2],
        notification: {
          identifier: `${mobileNo2} ${mobileNo1}`,
          icon_url:
            "https://genweappassets.s3.ap-south-1.amazonaws.com/loading+icon.png",
          thumbnail_url:
            "https://genweappassets.s3.ap-south-1.amazonaws.com/loading+icon.png",
          text: `Congratulations! You have successfully paired with your GenWE Peer and earned 200 points!`,
        },
      });
    }
    let messageToShow;
    if (message?.message) {
      messageToShow = message?.message;
    } else if (message?.channels?.length > 1) {
      messageToShow = `${message?.channels[1]?.me} is paired with ${message?.channels[1]?.buddy}`;
    }
    setError(`${errorToShow} because ${messageToShow}`);
  }
};

const unpairUser = async (mobileNo, reason, setMessage, setError) => {
  logger.log(mobileNo, "mobileNo unpairUser");
  try {
    logger.log(mobileNo, "mobileNo unpairUser");
    const data = await apiClient.post(apiUrls.unpairUser, {
      user: mobileNo,
      unpair_reason: reason,
    });
    const buddyId = data?.data[1]?.buddy_id;
    logger.log(data, "response-unpairUser");

    if (data?.length === 0) {
      setError("Can't unpair");
    } else {
      addUserData({
        type: "customer",
        customer_id: mobileNo,
        attributes: {
          pairing_status: "unpaired",
          last_unpairing_date: new Date(),
          peer: "",
          last_peer_id: buddyId,
          peer_name: "",
        },
      });
      addUserData({
        type: "customer",
        customer_id: buddyId,
        attributes: {
          pairing_status: "unpaired",
          last_unpairing_date: new Date(),
          peer: "",
          last_peer_id: mobileNo,
          peer_name: "",
        },
      });
      addUserEvent({
        type: "event",
        customer_id: buddyId,
        actions: [{
          "action": "user_unpaired",
          "attributes": {
            pairing_status: "unpaired",
            last_unpairing_date: new Date(),
            last_peer_id: mobileNo,
            reason
          },
        }],
      })
      addUserEvent({
        type: "event",
        customer_id: mobileNo,
        actions: [{
          "action": "user_unpaired",
          "attributes": {
            pairing_status: "unpaired",
            last_unpairing_date: new Date(),
            last_peer_id: buddyId,
            reason
          },
        }],
      })
      setMessage("Successfully unpaired");
    }
    return data;
  } catch (error) {
    logger.log(error.response, "error-unpairUser");
    setError(error.response?.data?.error?.error);
  }
};

const deleteUser = async (userId) => {
  logger.log(userId, "deleteUser");
  try {
    const data = await apiClient.get(apiUrls.deleteUser(userId));
    logger.log(data, "response-deleteUser");
    return data;
  } catch (error) {
    logger.log(error, "error-deleteUser");
  }
};

const blockUser = async (userId) => {
  logger.log(userId, "blockUser");
  try {
    const data = await apiClient.get(apiUrls.blockUser(userId));
    logger.log(data, "response-blockUser");
    return data;
  } catch (error) {
    logger.log(error, "error-blockUser");
  }
};

const unblockUser = async (userId) => {
  logger.log(userId, "unblockUser");
  try {
    const data = await apiClient.get(apiUrls.unblockUser(userId));
    logger.log(data, "response-unblockUser");
    return data;
  } catch (error) {
    logger.log(error, "error-unblockUser");
  }
};

export {
  genstat,
  getUsers,
  updateProfileKey,
  pairUser,
  updateProfileMultipleKeys,
  deleteUser,
  unpairUser,
  blockUser,
  unblockUser,
};
