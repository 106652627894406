import apiUrls from "../../apis/apis";
import {
  MY_ACTIVITIES_REQUEST,
  MY_ACTIVITIES_SUCCESS,
  MY_ACTIVITIES_FAILURE,
  GET_PROGRAM_DETAILS_REQUEST,
  GET_PROGRAM_DETAILS_SUCCESS,
  GET_PROGRAM_DETAILS_FAILURE,
  GET_ACTIVITY_DETAILS_REQUEST,
  GET_ACTIVITY_DETAILS_SUCCESS,
  GET_ACTIVITY_DETAILS_FAILURE,
  ACTIVITY_QNA_FAILURE,
  ACTIVITY_QNA_REQUEST,
  ACTIVITY_QNA_SUCCESS,
} from "./actions";
import apiClient from "../../apis/api-client";
import logger from "../../utils/logger";

const getConnects = (user_id) => async (dispatch) => {
  logger.log(user_id, "user-id-myActivities");
  dispatch({ type: MY_ACTIVITIES_REQUEST });
  try {
    const { data } = await apiClient.get(apiUrls.getMyActivities(user_id));
    logger.log(data, "response-myActivities");
    dispatch({ type: MY_ACTIVITIES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MY_ACTIVITIES_FAILURE,
      payload: error.response,
    });
    logger.log(error, "error-myActivities");
  }
};

const getConnectDetails = (program_id, user_id) => async (dispatch) => {
  logger.log(program_id, "program-id-program-details");
  dispatch({ type: GET_PROGRAM_DETAILS_REQUEST });
  try {
    const { data } = await apiClient.get(
      apiUrls.getProgramDetails(program_id, user_id)
    );
    logger.log(data, "response-program-details");
    dispatch({ type: GET_PROGRAM_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_PROGRAM_DETAILS_FAILURE,
      payload: error.response,
    });
    logger.log(error, "error-program-details");
  }
};

const createProgram = (dataToSend) => async (dispatch) => {
  logger.log(dataToSend, "create-program-data");
  try {
    const { data } = await apiClient.post(apiUrls.createProgram, dataToSend);
    logger.log(data, "response-create-program");
    dispatch(getConnects("8881831836"));
  } catch (error) {
    logger.log(error, "error-create-program");
  }
};

const createActivity = async (dataToSend) => {
  logger.log(dataToSend, "create-activity-data");
  try {
    const { data } = await apiClient.post(apiUrls.createActivity, dataToSend);
    logger.log(data, "response-create-activity");
  } catch (error) {
    logger.log(error, "error-create-activity");
  }
};

export { getConnects, getConnectDetails, createProgram, createActivity };
