import defaultUserImage from './defaultUserImage.jpg';
import x from './x.png';
import logo from './logo.png';

const images = {
  defaultUserImage: defaultUserImage,
  x: x,
  logo: logo,
};
export default images;
