import {
  MY_ACTIVITIES_REQUEST,
  MY_ACTIVITIES_SUCCESS,
  MY_ACTIVITIES_FAILURE,
  GET_PROGRAM_DETAILS_REQUEST,
  GET_PROGRAM_DETAILS_SUCCESS,
  GET_PROGRAM_DETAILS_FAILURE,
  SET_ONGOING_ACTIVITY_DETAILS,
  GET_ACTIVITY_DETAILS_REQUEST,
  GET_ACTIVITY_DETAILS_SUCCESS,
  GET_ACTIVITY_DETAILS_FAILURE,
  ACTIVITY_QNA_FAILURE,
  ACTIVITY_QNA_REQUEST,
  ACTIVITY_QNA_SUCCESS,
} from "./actions";

const initialState = {};

const connects = (state = initialState, action) => {
  switch (action.type) {
    case MY_ACTIVITIES_REQUEST: {
      return { ...state, loading: true };
    }
    case MY_ACTIVITIES_SUCCESS: {
      return { ...state, activitySubjects: action.payload, loading: false };
    }
    case MY_ACTIVITIES_FAILURE: {
      return { ...state, data: action.payload, loading: false };
    }

    case GET_PROGRAM_DETAILS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_PROGRAM_DETAILS_SUCCESS: {
      return { ...state, programDetails: action.payload, loading: false };
    }
    case GET_PROGRAM_DETAILS_FAILURE: {
      return { ...state, data: action.payload, loading: false };
    }
    case GET_ACTIVITY_DETAILS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_ACTIVITY_DETAILS_SUCCESS: {
      return { ...state, activityDetails: action.payload, loading: false };
    }
    case GET_ACTIVITY_DETAILS_FAILURE: {
      return { ...state, data: action.payload, loading: false };
    }
    case GET_ACTIVITY_DETAILS_REQUEST: {
      return { ...state, loading: true };
    }
    case ACTIVITY_QNA_SUCCESS: {
      return { ...state, activityQna: action.payload, loading: false };
    }
    case MY_ACTIVITIES_FAILURE: {
      return { ...state, data: action.payload, loading: false };
    }
    case ACTIVITY_QNA_REQUEST: {
      return { ...state, loading: true };
    }
    case "CLEAR_ACTIVITY_DETAILS": {
      return { ...state, activityDetails: {}, loading: false };
    }
    default:
      return state;
  }
};

export default connects;
