import pageLimits from "../../utils/page-limits";

const initialState = {};

const qnas = (state = initialState, action) => {
  console.log(action.type, "action.type");
  switch (action.type) {
    case "ALL_QNAS_REQUEST": {
      return { ...state, loading: true };
    }
    case "ALL_QNAS_SUCCESS": {
      let items = state.allQnas?.docs?.slice(
        0,
        pageLimits.allQnas * (action.payload.page - 1)
      );
      return {
        ...state,
        allQnas:
          action.payload.page === 1
            ? action.payload
            : {
                page: action.payload.page,
                pages: action.payload.pages,
                docs: [...items, ...action.payload.docs],
                total: action.payload.total,
              },
        loading: false,
      };
    }
    case "ALL_QNAS_FAILURE": {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};
export default qnas;
