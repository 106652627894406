import React from "react";
import MenuAppBar from "../routes/header";
import ReportsSideBar from "../reports/sidebar";
import Filters from "../reports/filters";

const FiltersWrapper = ({ children, heading }) => {
  return (
    <div style={{ flex: 1, flexDirection: "column" }}>
      <MenuAppBar heading={heading} />
      <div
        style={{
          marginTop: 60,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ReportsSideBar />
        <div
          style={{
            flexDirection: "column",
            marginLeft: 20,
            display: "flex",
          }}
        >
          <Filters />
          {children}
        </div>
      </div>
    </div>
  );
};

export default FiltersWrapper;
